import React, { useEffect } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export default function LocalVideoPreview() {
  const { localTracks, room } = useVideoContext();
  const { localParticipant } = room;

  const videoTrack = localTracks.find(track =>
    track.name.includes('camera')
  ) as LocalVideoTrack;

  useEffect(() => {
    return () => {
      videoTrack?.stop();
    };
  }, [videoTrack]);

  return videoTrack ? (
    <VideoTrack track={videoTrack} participant={localParticipant} isLocal />
  ) : null;
}
