import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyle from '../../global-styles';
import { lightTheme, darkTheme } from '../../styles/theme';
import { useAppState } from '../../state';

const Layout = styled.div.attrs({ className: 'app' })`
  position: relative;
`;

interface Props {
  children?: JSX.Element | JSX.Element[];
}

export const VideoAppLayout = ({ children }: Props) => {
  const { isDark } = useAppState();
  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <Layout data-theme={!isDark ? 'light' : 'dark'}>{children}</Layout>
      <ToastContainer />
      <GlobalStyle theme={!isDark ? 'light' : 'dark'} />
    </ThemeProvider>
  );
};
