import React, { useState, MouseEvent, ChangeEvent } from 'react';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { UPDATE_USER } from '../../graphql/mutations/User';
import { Helmet } from 'react-helmet';
import { Card } from '../Card/Card';
import { displayNotificationToast, displayErrorToast } from '../../utils/toast';

export default function ProfileSettings() {
  const [displayName, setDisplayName] = useState<string>('');
  const [age, setAge] = useState<string>('');
  // const [whereabouts, setWhereabouts] = useState<string>('');
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [displayNameValidationError, setDisplayNameValidationError] = useState<
    boolean
  >(false);

  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      setDisplayName(me?.displayName || '');
      setAge(me?.age || '');
      // setWhereabouts(me?.location || '');
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      id: getMeData?.me?.id,
      displayName,
      age: parseInt(age),
      // location: whereabouts,
    },
    onCompleted: data =>
      displayNotificationToast('Your profile was successfully updated!'),
    onError: err =>
      displayErrorToast('Something went wrong. Please try again later.'),
  });

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let isError = false;

    if (!displayName) {
      setDisplayNameValidationError(true);
      isError = true;
    } else {
      setDisplayNameValidationError(false);
    }

    if (isError) {
      return;
    }

    updateUser();
  };

  return (
    <>
      <Helmet>
        <title>My Profile</title>
        <meta name="description" content="Manage your profile information" />
      </Helmet>

      <Card className="ProfileSettings">
        <h2 style={{ marginBottom: 24 }}>My Profile</h2>

        <label htmlFor="display-name" className="mktg form__label">
          Display name
        </label>
        <TextInput
          type="text"
          value={displayName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setChangesMade(e.target.value !== getMeData?.me?.displayName);
            setDisplayName(e.target.value);
          }}
          placeholder="Display name"
          ctnStyle={{ marginBottom: 16 }}
        />

        <h6 style={{ margin: '0 0 24px 0' }}>
          This is what users will see when they see you.
        </h6>

        {displayNameValidationError && (
          <div className="validation-error">You need a display name</div>
        )}

        <hr />

        <label htmlFor="age" className="mktg form__label">
          Age
        </label>
        <TextInput
          type="text"
          value={age}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setChangesMade(e.target.value !== getMeData?.me?.age);
            setAge(e.target.value);
          }}
          placeholder="My age"
          ctnStyle={{ marginBottom: 16 }}
        />

        <h6 style={{ margin: '0 0 24px 0' }}>
          You must be 18 years or older to use Metanet.
        </h6>

        {/* <hr /> */}

        {/* <label htmlFor="display-name" className="mktg form__label">
          Location&nbsp;
          <span className="subtitle">(optional)</span>
        </label> */}
        {/* <TextInput
          type="text"
          value={whereabouts}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setChangesMade(e.target.value !== getMeData?.me?.location);
            setWhereabouts(e.target.value);
          }}
          placeholder="My location"
          ctnStyle={{ marginBottom: 16 }}
        /> */}

        {/* <div className="subtitle" style={{ margin: '0 0 40px 0' }}>
          Location will be used for matching purposes.
        </div> */}

        <Button
          lg
          size="height-40"
          type="button"
          color="primary"
          style={{ width: '100%' }}
          onClick={handleSubmit}
          disabled={!changesMade}
        >
          Update My Information
        </Button>
      </Card>
    </>
  );
}
