import gql from 'graphql-tag';

export const GET_UNIVERSE = gql`
  query Universe($id: String) {
    universe(id: $id) {
      id
      name
      type
      description
      xDimension
      yDimension
      zDimension
      isPrivate
      createdBy {
        id
      }
      currentParticipants {
        id
      }
      members {
        id
      }
    }
  }
`;

export const GET_UNIVERSES = gql`
  query Universes($createdBy: String, $isPrivate: Boolean, $cursor: String) {
    universes(
      createdBy: $createdBy
      isPrivate: $isPrivate
      first: 50
      after: $cursor
    ) {
      edges {
        node {
          id
          name
          type
          description
          xDimension
          yDimension
          zDimension
          isPrivate
          createdBy {
            id
          }
          currentParticipants {
            id
          }
          members {
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
