import React, { useState, MouseEvent, ChangeEvent } from 'react';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { Helmet } from 'react-helmet';
import { Card } from '../Card/Card';
import { displayNotificationToast, displayErrorToast } from '../../utils/toast';
import axios from 'axios';

export default function PasswordSecuritySettings() {
  const [existingPassword, setExistingPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');
  const [changesMade, setChangesMade] = useState<boolean>(false);

  const updatePassword = () => {
    axios
      .post(
        `${
          process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_DEVELOPMENT_API_URL
        }/updatepassword`,
        { existingPassword, newPassword },
        { withCredentials: true }
      )
      .then(res =>
        displayNotificationToast('Your password was successfully updated!')
      )
      .catch(err =>
        displayErrorToast(
          'Something went wrong. Check your password and try again.'
        )
      );
  };

  const verifyChanges = () =>
    setChangesMade(
      existingPassword !== '' && newPassword !== '' && repeatNewPassword !== ''
    );

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (newPassword === repeatNewPassword) {
      updatePassword();
    } else {
      displayErrorToast('Passwords do not match.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Password & Security</title>
        <meta name="description" content="Update your password" />
      </Helmet>

      <Card className="PasswordSecuritySettings">
        <h2 style={{ marginBottom: 24 }}>Password & Security</h2>

        <label htmlFor="display-name" className="mktg form__label">
          Existing password
        </label>
        <TextInput
          type="password"
          value={existingPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setExistingPassword(e.target.value);
            verifyChanges();
          }}
          placeholder="Existing password"
          ctnStyle={{ marginBottom: 24 }}
        />

        <hr />

        <label htmlFor="display-name" className="mktg form__label">
          New password
        </label>
        <TextInput
          type="password"
          value={newPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setNewPassword(e.target.value);
            verifyChanges();
          }}
          placeholder="New password"
          ctnStyle={{ marginBottom: 24 }}
        />

        <label htmlFor="display-name" className="mktg form__label">
          Repeat new password
        </label>
        <TextInput
          type="password"
          value={repeatNewPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setRepeatNewPassword(e.target.value);
            verifyChanges();
          }}
          placeholder="Repeat new password"
          ctnStyle={{ marginBottom: 24 }}
        />

        <h6 style={{ margin: '0 0 40px 0' }}>
          Use a password with at least one special character and one number.
          Don't re-use passwords from other sites.
        </h6>

        <Button
          lg
          size="height-40"
          type="button"
          color="primary"
          style={{ width: '100%' }}
          onClick={handleSubmit}
          disabled={!changesMade}
        >
          Update My Password
        </Button>
      </Card>
    </>
  );
}
