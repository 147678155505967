const purple = '#2E36F2';
const purpleHighlight = 'rgba(164, 167, 255, 0.35)';

const pink = '#FF3DB2';
const pinkHighlight = '#FFECF7';

const attention = purple;
const attentionHighlight = purpleHighlight;

export const lightTheme = {
  white: ``,
  text: ``,
  dark1: ``,
  dark2: ``,
  dark3: ``,
  border: ``,
  borderColor: ``,
  gray: ``,
  purple,
  purpleHighlight,
  pink,
  pinkHighlight,
  attention,
  attentionHighlight,
};

export const darkTheme = {
  white: `#FFFFFF`,
  text: `#FFFFFF`,
  dark1: `#202020`,
  dark2: `#242525`,
  dark3: `#303030`,
  border: `1px solid #555656`,
  borderColor: `#555656`,
  gray: `#A0A4B5`,
  purple,
  purpleHighlight,
  pink,
  pinkHighlight,
  attention,
  attentionHighlight,
};
