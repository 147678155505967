import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ChatExpand } from '../../../assets/icons/chat-expand.svg';
import { ReactComponent as ChatMinimize } from '../../../assets/icons/chat-minimize.svg';

const Button = styled.button`
  cursor: pointer;
  margin-right: 8px;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  path {
    fill: #ffffff;
  }
`;

export default function ToggleChatButton(props: {
  isChatOpen: boolean;
  toggleIsChatOpen: any;
  disabled?: boolean;
  buttonColor?: string;
}) {
  return (
    <Button onClick={props.toggleIsChatOpen}>
      {props.isChatOpen ? <ChatMinimize /> : <ChatExpand />}
    </Button>
  );
}
