import gql from 'graphql-tag';

// export const CREATE_GUEST = gql`
//   mutation CreateGuest($username: String) {
//     createGuest(input: { username: $username }) {
//       user {
//         id
//         username
//       }
//     }
//   }
// `;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $phoneNumber: String
    $displayName: String
    $age: Int
    $roomInMetaspaceX: Int
    $roomInMetaspaceY: Int
    $roomInMetaspaceZ: Int
    $avatarInRoomX: Int
    $avatarInRoomY: Int
    $professionPreference: ID
    $gender: ID
    $sexualOrientation: ID
    $showMeIn: String
    $showMe: String
    $eventCode: String
    $isCurrentlyParticipatingInEvent: Boolean
    $facebookUsername: String
    $instagramUsername: String
    $twitterUsername: String
    $tikTokUsername: String
    $linkedInUsername: String
  ) {
    updateUser(
      input: {
        id: $id
        phoneNumber: $phoneNumber
        displayName: $displayName
        age: $age
        roomInMetaspaceX: $roomInMetaspaceX
        roomInMetaspaceY: $roomInMetaspaceY
        roomInMetaspaceZ: $roomInMetaspaceZ
        avatarInRoomX: $avatarInRoomX
        avatarInRoomY: $avatarInRoomY
        professionPreference: $professionPreference
        gender: $gender
        sexualOrientation: $sexualOrientation
        showMeIn: $showMeIn
        showMe: $showMe
        eventCode: $eventCode
        isCurrentlyParticipatingInEvent: $isCurrentlyParticipatingInEvent
        facebookUsername: $facebookUsername
        instagramUsername: $instagramUsername
        twitterUsername: $twitterUsername
        tikTokUsername: $tikTokUsername
        linkedInUsername: $linkedInUsername
      }
    ) {
      user {
        id
        phoneNumber
        displayName
        age
        roomInMetaspaceX
        roomInMetaspaceY
        roomInMetaspaceZ
        avatarInRoomX
        avatarInRoomY
        gender {
          id
          value
        }
        sexualOrientation {
          id
          value
        }
        showMe
        showMeIn
        professionPreference {
          id
          value
        }
        isCurrentlyParticipatingInEvent
        facebookUsername
        instagramUsername
        twitterUsername
        tikTokUsername
        linkedInUsername
        createdAt
        updatedAt
        lastLoginAt
      }
    }
  }
`;

export const RESET_NEXT_SKIP_AT = gql`
  mutation ResetNextSkipAt($id: ID!) {
    resetNextSkipAt(input: { id: $id }) {
      user {
        id
        username
        nextSkipAt
        updatedAt
        createdAt
      }
    }
  }
`;
