import React from 'react';
import clsx from 'clsx';
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  // LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  // RemoteAudioTrack,
  RemoteVideoTrack,
} from 'twilio-video';

// import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
// import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
// import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
// import ParticipantConnectionIndicator from './ParticipantConnectionIndicator/ParticipantConnectionIndicator';
// import PinIcon from './PinIcon/PinIcon';
// import ScreenShare from '@material-ui/icons/ScreenShare';
// import VideocamOff from '@material-ui/icons/VideocamOff';

// import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useTrack from '../../hooks/useTrack/useTrack';
// import usePublicationIsTrackEnabled from '../../hooks/usePublicationIsTrackEnabled/usePublicationIsTrackEnabled';
import VideoPlaceholder from '../VideoPlaceholder/VideoPlaceholder';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     isVideoSwitchedOff: {
//       '& video': {
//         filter: 'blur(4px) grayscale(1) brightness(0.5)',
//       },
//     },
//     identity: {
//       background: 'rgba(0, 0, 0, 0.7)',
//       padding: '0.1em 0.3em',
//       margin: 0,
//       display: 'flex',
//       alignItems: 'center',
//     },
//     infoRow: {
//       display: 'flex',
//       justifyContent: 'universe-between',
//     },
//   })
// );

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  // isSelected,
  children,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  // const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p =>
    p.trackName.includes('camera')
  );

  // const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  // const isAudioEnabled = usePublicationIsTrackEnabled(audioPublication);
  const isVideoEnabled = Boolean(videoPublication);
  // const isScreenShareEnabled = publications.find(p =>
  //   p.trackName.includes('screen')
  // );

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  // const audioTrack = useTrack(audioPublication) as
  //   | LocalAudioTrack
  //   | RemoteAudioTrack;

  // const classes = useStyles();

  return (
    <div
      className={clsx('ParticipantInfo', { 'video-off': isVideoSwitchedOff })}
      onClick={onClick}
      data-cy-participant={participant?.identity}
    >
      <div className="ParticipantInfoContainer">
        {/* {
          process.env.NODE_ENV === 'development' &&
          <div className={classes.infoRow}>
            <h5 className={classes.identity}>
              <ParticipantConnectionIndicator participant={participant} />
              {participant.identity}
            </h5>
            <NetworkQualityLevel qualityLevel={networkQualityLevel} />
          </div>
        } */}
        {!isVideoEnabled && <VideoPlaceholder participant={participant} />}
        {/* <div style={{ opacity: 0, height: 0 }}> */}
        {/* <AudioLevelIndicator audioTrack={audioTrack} background="white" /> */}
        {/* {!isVideoEnabled && <VideocamOff />} */}
        {/* {isScreenShareEnabled && <ScreenShare />} */}
        {/* {isSelected && <PinIcon />} */}
        {/* </div> */}
      </div>
      {/* {isVideoSwitchedOff && <BandwidthWarning />} */}
      {children}
    </div>
  );
}
