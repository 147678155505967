import styled from 'styled-components';

interface CardInterface {
  disabled?: boolean;
}

export const Card = styled.div<CardInterface>`
  // border: 1px solid #e5e7e9;
  box-shadow: 0px 0px 12px rgba(229, 229, 229, 0.64);
  background: #ffffff;
  position: relative;
  // animation-duration: 1s;
  // animation-delay: 1.5s;
  // width: 100%;
  overflow: hidden;

  @media screen and (min-width: 641px) {
    border-radius: 10px;
  }

  @media screen and (max-width: 640px) {
    border-radius: 0;
  }

  [data-theme='dark'] & {
    // border: 1px solid #555656;
    border-radius: 10px;
    box-shadow: none;
    background: ${({ theme }) => theme.dark2};

    @media screen and (max-width: 640px) {
      border: none;
      border-radius: 0;
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}
`;
