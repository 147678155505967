import gql from 'graphql-tag';

export const ROOMS_SUBSCRIPTION = gql`
  subscription OnRoomParticipantsChanged(
    $roomId: String
    $xCoord: Int
    $yCoord: Int
    $zCoord: Int
  ) {
    roomParticipantsChanged(
      roomId: $roomId
      xCoord: $xCoord
      yCoord: $yCoord
      zCoord: $zCoord
    ) {
      id
      currentParticipants {
        id
      }
    }
  }
`;
