import gql from 'graphql-tag';

export const JOIN_ROOM = gql`
  mutation JoinRoom($xCoord: Int, $yCoord: Int, $zCoord: Int, $userId: ID) {
    joinRoom(
      input: {
        xCoord: $xCoord
        yCoord: $yCoord
        zCoord: $zCoord
        userId: $userId
      }
    ) {
      token
      roomId
      status
    }
  }
`;

export const LEAVE_ROOM = gql`
  mutation LeaveRoom($roomId: String, $userId: ID) {
    leaveRoom(input: { userId: $userId, roomId: $roomId }) {
      status
    }
  }
`;

export const LOCATE_NEAREST_AVAILABLE_ROOM = gql`
  mutation LocateNearestAvailableRoom {
    locateNearestAvailableRoom {
      xCoord
      yCoord
      zCoord
      status
    }
  }
`;

export const JOIN_ROOM_CONNECTED_PARTICIPANTS_LIST = gql`
  mutation JoinRoomConnectedParticipantsList(
    $xCoord: Int
    $yCoord: Int
    $zCoord: Int
    $userId: ID
  ) {
    joinRoomConnectedParticipantsList(
      input: {
        xCoord: $xCoord
        yCoord: $yCoord
        zCoord: $zCoord
        userId: $userId
      }
    ) {
      status
    }
  }
`;
