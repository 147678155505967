import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { UPDATE_USER } from '../../graphql/mutations/User';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import BackLink from '../BackLink/BackLink';

export default function WhatsYourSocials() {
  const [facebookUsername, setFacebookUsername] = useState<string>('');
  const [instagramUsername, setInstagramUsername] = useState<string>('');
  const [twitterUsername, setTwitterUsername] = useState<string>('');
  const [tikTokUsername, setTikTokUsername] = useState<string>('');
  const [linkedInUsername, setLinkedInUsername] = useState<string>('');
  // const [usernameValidationError, setUsernameValidationError] = useState<
  //   boolean
  // >(false);
  const history = useHistory();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      setFacebookUsername(me?.facebookUsername || '');
      setInstagramUsername(me?.instagramUsername || '');
      setTwitterUsername(me?.twitterUsername || '');
      setTikTokUsername(me?.tikTokUsername || '');
      setLinkedInUsername(me?.linkedInUsername || '');
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      id: getMeData?.me?.id,
      facebookUsername,
      instagramUsername,
      twitterUsername,
      tikTokUsername,
      linkedInUsername,
    },
    onCompleted: data => history.push('/home'),
    onError: err => console.error(err),
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateUser();
  };

  return (
    <div
      className="WhatsYourSocials onboarding"
      style={{
        height: isTabletOrMobile ? height : height - 100,
        paddingBottom: 40,
      }}
    >
      <Helmet>
        <title>Social media accounts</title>
        <meta
          name="description"
          content="Where else can we find you on the internet?"
        />
      </Helmet>

      <BackLink absoluteDesktop={true} />
      <h1
        className="dsktp mktg gradient-purple-pink"
        style={{ margin: '0 0 24px' }}
      >
        Help us get to know you better
      </h1>
      <div className="section-title--mktg">Add your social media accounts</div>
      <form
        className="onboarding__form"
        onSubmit={handleSubmit}
        style={{ marginTop: 24 }}
      >
        {/* <label htmlFor="display-name" className="mktg form__label">
          Facebook&nbsp;
          <span className="subtitle">(optional)</span>
        </label>
        <TextInput
          type="text"
          value={facebookUsername}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFacebookUsername(e.target.value)}
          placeholder="Facebook username"
          ctnStyle={{ marginBottom: 16 }}
        /> */}

        <label htmlFor="display-name" className="mktg form__label">
          Instagram&nbsp;
          <span className="subtitle">(optional)</span>
        </label>
        <TextInput
          type="text"
          value={instagramUsername}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInstagramUsername(e.target.value)
          }
          placeholder="Instagram username"
          ctnStyle={{ marginBottom: 16 }}
        />

        <label htmlFor="display-name" className="mktg form__label">
          Twitter&nbsp;
          <span className="subtitle">(optional)</span>
        </label>
        <TextInput
          type="text"
          value={twitterUsername}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setTwitterUsername(e.target.value)
          }
          placeholder="Twitter username"
          ctnStyle={{ marginBottom: 16 }}
        />

        <label htmlFor="display-name" className="mktg form__label">
          TikTok&nbsp;
          <span className="subtitle">(optional)</span>
        </label>
        <TextInput
          type="text"
          value={tikTokUsername}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setTikTokUsername(e.target.value)
          }
          placeholder="TikTok username"
          ctnStyle={{ marginBottom: 16 }}
        />

        <label htmlFor="display-name" className="mktg form__label">
          LinkedIn&nbsp;
          <span className="subtitle">(optional)</span>
        </label>
        <TextInput
          type="text"
          value={linkedInUsername}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLinkedInUsername(e.target.value)
          }
          placeholder="LinkedIn username"
          ctnStyle={{ marginBottom: 16 }}
        />

        <Button
          lg
          size="height-40"
          type="submit"
          color="gradient-purple-pink"
          style={{ width: '100%', marginTop: 24 }}
        >
          Those are my accounts
        </Button>

        <div
          className="subtitle"
          style={{ margin: '40px 0', textAlign: 'center' }}
        >
          Your linked social media accounts will help us verify your identity
          and better understand your interests.
        </div>
        {/* <div className="subtitle" style={{ margin: '64px 0 0', textAlign: 'center' }}>
          Note: We strive to remove unconscious bias from our matchmaking process and pledge to continously fine-tune
          our algorithms to make sure that we emphasize what's important - the stuff inside of you!
        </div> */}
      </form>
    </div>
  );
}
