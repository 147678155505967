import { Coordinates, Dimensions } from '../models/Entities';
import { CARTESIAN_QUADRANT } from '../constants';

/**
 * Quadrant is either 1 2 3 4 in two-dimensional Cartesian system starting in middle of room
 * II   I
 * III  IV
 *
 * Triangle coordinates for Quadrant I (+; +)
 * (1440, 960)
 * (2880, 960)
 * (1440, 1920)
 *
 * Triangle coordinates for Quadrant II (-; +)
 * (0, 960)
 * (1440, 960)
 * (1440, 1920)
 *
 * Triangle coordinates for Quadrant III (-; -)
 * (0, 960)
 * (1440, 960)
 * (1440, 0)
 *
 * Triangle coordinates for Quadrant IV (+; -)
 * (1440, 960)
 * (2880, 960)
 * (1440, 0)
 */

export const determineCartesianQuadrant = (
  { x, y }: Coordinates,
  roomDimensions: Dimensions
) => {
  const midX = roomDimensions.x / 2;
  const midY = roomDimensions.y / 2;
  if (x >= midX && y >= midY) {
    return CARTESIAN_QUADRANT.I;
  } else if (x < midX && y >= midY) {
    return CARTESIAN_QUADRANT.II;
  } else if (x < midX && y < midY) {
    return CARTESIAN_QUADRANT.III;
  } else if (x >= midX && y < midY) {
    return CARTESIAN_QUADRANT.IV;
  }
};

/**
 * Determine which side of the half-plane created by edges that the point is
 */
const sign = (p1: Coordinates, p2: Coordinates, p3: Coordinates) => {
  return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y);
};

const isPointInTriangle = (
  pt: Coordinates,
  v1: Coordinates,
  v2: Coordinates,
  v3: Coordinates
) => {
  let d1: number, d2: number, d3: number;
  let has_neg: boolean, has_pos: boolean;

  d1 = sign(pt, v1, v2);
  d2 = sign(pt, v2, v3);
  d3 = sign(pt, v3, v1);

  has_neg = d1 < 0 || d2 < 0 || d3 < 0;
  has_pos = d1 > 0 || d2 > 0 || d3 > 0;

  return !(has_neg && has_pos);
};

export const isCoordinatesWithinQuadrantTriangle = (
  coordinates: Coordinates,
  quadrant: CARTESIAN_QUADRANT,
  roomDimensions: Dimensions
) => {
  if (quadrant === 1) {
    return isPointInTriangle(
      coordinates,
      { x: roomDimensions.x / 2, y: roomDimensions.y / 2 },
      { x: roomDimensions.x, y: roomDimensions.y / 2 },
      { x: roomDimensions.x / 2, y: roomDimensions.y }
    );
  } else if (quadrant === 2) {
    return isPointInTriangle(
      coordinates,
      { x: 0, y: roomDimensions.y / 2 },
      { x: roomDimensions.x / 2, y: roomDimensions.y / 2 },
      { x: roomDimensions.x / 2, y: roomDimensions.y }
    );
  } else if (quadrant === 3) {
    return isPointInTriangle(
      coordinates,
      { x: 0, y: roomDimensions.y / 2 },
      { x: roomDimensions.x / 2, y: roomDimensions.y / 2 },
      { x: roomDimensions.x / 2, y: 0 }
    );
  } else if (quadrant === 4) {
    return isPointInTriangle(
      coordinates,
      { x: roomDimensions.x / 2, y: roomDimensions.y / 2 },
      { x: roomDimensions.x, y: roomDimensions.y / 2 },
      { x: roomDimensions.x / 2, y: 0 }
    );
  }
};
