import React from 'react';
import styled from 'styled-components';
import { Participant as IParticipant } from 'twilio-video';
import { useQuery } from '@apollo/react-hooks';
import MicOff from '@material-ui/icons/MicOff';

import Participant from '../Participant/Participant';

import { GET_USER } from '../../graphql/queries/User';

import usePublications from '../../hooks/usePublications/usePublications';
import usePublicationIsTrackEnabled from '../../hooks/usePublicationIsTrackEnabled/usePublicationIsTrackEnabled';

const ParticipantContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`;

const StyledParticipant = styled.div`
  position: relative;
  z-index: 1;
`;

const ParticipantFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
`;

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 640px) {
    border-radius: 0;
  }

  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 56.25%;
  }

  &:after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }

  video {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;
    display: block;

    @media screen and (min-width: 641px) {
      max-height: 60vh;
    }
  }
`;

interface LocalParticipantProps {
  participant: IParticipant;
}

export default function LocalParticipant({
  participant,
}: LocalParticipantProps) {
  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const isAudioEnabled = usePublicationIsTrackEnabled(audioPublication);

  const { data: getUserData } = useQuery(GET_USER, {
    variables: { id: participant?.identity || '' },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <ParticipantContainer>
      <StyledParticipant>
        <VideoContainer>
          <Participant
            participant={participant}
            isSelected={false}
            onClick={() => {}}
          />
        </VideoContainer>
        <ParticipantFooter>
          <h5 className="white">{getUserData?.user?.displayName}</h5>
          {!isAudioEnabled && (
            <MicOff
              className="muted-icon"
              style={{ marginLeft: 8, width: '16px', height: '16px' }}
            />
          )}
        </ParticipantFooter>
      </StyledParticipant>
    </ParticipantContainer>
  );
}
