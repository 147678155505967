import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import Minimap from '../Minimap/Minimap';
import AccountMenu from '../AccountMenu/AccountMenu';

import useRoomState from '../../hooks/useRoomState/useRoomState';

import { GET_ME } from '../../graphql/queries/Me';

import SpaceAvatar from '../../assets/images/box/box.png';

import { Coordinates } from '../../models/Entities';

const RoomName = styled.h5.attrs({ className: 'termina white' })`
  margin-left: 8px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
`;

const CurrentCoordinates = styled.h5.attrs({ className: 'gray' })`
  font-size: 13px;
  line-height: 18px;
  margin-left: 8px;
  font-weight: 400;
`;

const LoadingText = styled.h5.attrs({ className: 'gray ellipsis' })`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-left: 8px;
`;

type Props = {
  myCoords: Coordinates;
  setCoords: any;
};

export default function MetaspaceHeader({ myCoords, setCoords }: Props) {
  const { x: myX, y: myY, z: myZ } = myCoords;
  const roomState = useRoomState();

  const { refetch } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div>
      <div className="Metaspace__upper-left">
        <div className="Metaspace__upper-left__header">
          <Link to="/home">
            <img src={SpaceAvatar} alt="" />
          </Link>
          <RoomName>Metanet</RoomName>
          <CurrentCoordinates>{`(${myX}, ${myY}, ${myZ})`}</CurrentCoordinates>
          {roomState !== 'connected' && <LoadingText>Joining</LoadingText>}
        </div>
        <Minimap myCoords={myCoords} setCoords={setCoords} />
      </div>
      <div className="Metaspace__upper-right">
        <AccountMenu refetch={refetch} />
      </div>
    </div>
  );
}
