import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import apollo from './lib/apolloClient';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  QueryParamProvider,
  ExtendedStringifyOptions,
  transformSearchStringJsonSafe,
} from 'use-query-params';

import './types';
import './styles/main.scss';

import AppStateProvider from './state';
import { LandingLayout, AppLayout } from './components/layouts';
import VideoApp from './VideoApp';

import Landing from './pages/landing';
import SignIn from './components/SignIn/SignIn';
import CreateAccount from './components/CreateAccount/CreateAccount';
import Welcome from './components/Welcome/Welcome';
import Home from './pages/app/Home';
import Explore from './pages/app/Explore';
import Settings from './components/Settings/Settings';
import WhatsYourNumber from './components/WhatsYourNumber/WhatsYourNumber';
import WhatsYourName from './components/WhatsYourName/WhatsYourName';
import WhatsYourAge from './components/WhatsYourAge/WhatsYourAge';
import WhatsYourWhereabouts from './components/WhatsYourWhereabouts/WhatsYourWhereabouts';
import WhatsYourSocials from './components/WhatsYourSocials/WhatsYourSocials';
import DatingPreferences from './components/DatingPreferences/DatingPreferences';

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

const App = () => {
  /* hide Safari toolbars */
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i)
  ) {
    window.addEventListener('load', () => {
      setTimeout(() => {
        window.scrollTo(0, 1);
      }, 1000);
    });
  }

  return (
    <Switch>
      <Route exact path="/">
        <LandingLayout>
          <Landing />
        </LandingLayout>
      </Route>
      <Route path="/create-account">
        <AppLayout>
          <CreateAccount />
        </AppLayout>
      </Route>
      <Route path="/sign-in">
        <AppLayout>
          <SignIn />
        </AppLayout>
      </Route>
      <Route path="/whats-your-number">
        <AppLayout>
          <WhatsYourNumber />
        </AppLayout>
      </Route>
      <Route path="/whats-your-name">
        <AppLayout>
          <WhatsYourName />
        </AppLayout>
      </Route>
      <Route path="/whats-your-age">
        <AppLayout>
          <WhatsYourAge />
        </AppLayout>
      </Route>
      <Route path="/whats-your-whereabouts">
        <AppLayout>
          <WhatsYourWhereabouts />
        </AppLayout>
      </Route>
      <Route path="/whats-your-socials">
        <AppLayout>
          <WhatsYourSocials />
        </AppLayout>
      </Route>
      <Route path="/dating-prefs">
        <AppLayout>
          <DatingPreferences />
        </AppLayout>
      </Route>
      <Route path="/welcome">
        <AppLayout>
          <Welcome />
        </AppLayout>
      </Route>
      <Route path="/m">
        <VideoApp />
      </Route>
      <Route path="/home">
        <AppLayout>
          <Home />
        </AppLayout>
      </Route>
      <Route path="/explore">
        <AppLayout>
          <Explore />
        </AppLayout>
      </Route>
      <Route path="/settings">
        <AppLayout>
          <Settings />
        </AppLayout>
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

ReactDOM.render(
  <Router>
    <QueryParamProvider
      ReactRouterRoute={Route}
      stringifyOptions={queryStringifyOptions}
    >
      <ApolloProvider client={apollo}>
        <AppStateProvider>
          <App />
        </AppStateProvider>
      </ApolloProvider>
    </QueryParamProvider>
  </Router>,
  document.getElementById('root')
);
