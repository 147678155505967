import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 24px 24px 5%;
`;

export const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;
