import gql from 'graphql-tag';

export const GET_ROOM_AT_COORDS = gql`
  query RoomAtCoords($xCoord: Int, $yCoord: Int, $zCoord: Int) {
    roomAtCoords(xCoord: $xCoord, yCoord: $yCoord, zCoord: $zCoord) {
      id
      currentParticipants {
        id
      }
    }
  }
`;

export const GET_ROOMS = gql`
  query Rooms($cursor: String) {
    rooms(first: 500, after: $cursor) {
      edges {
        node {
          id
          participants {
            id
          }
          next
          prev
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
