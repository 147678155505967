import React, { FormEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import { Button } from '../Button/Button';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import BackLink from '../BackLink/BackLink';
import LocalVideoPreview from '../LocalVideoPreview/LocalVideoPreview';
import styled from 'styled-components';
import { useQueryParam, StringParam } from 'use-query-params';

/**
 * TODO:
 * Mirror check should be an onboarding experience
 * - Show how to navigate
 * - Show how to customize room
 */

const VideoContainer = styled.div`
  height: 40%;
  max-height: 480px;
  width: auto;
  margin: 24px;
  video {
    height: 100%;
    width: 100%;
  }
`;

export default function MirrorCheck() {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();

  const [x] = useQueryParam('x', StringParam);
  const [y] = useQueryParam('y', StringParam);
  const [z] = useQueryParam('z', StringParam);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let url = `/m/space`;
    if (x && y && z) {
      url += `?x=${x}&y=${y}&z=${z}`;
    } else {
      url += `?x=0&y=0&z=0`;
    }
    history.push(url);
  };

  return (
    <div
      className="MirrorCheck onboarding"
      style={{
        height: isTabletOrMobile ? height : height - 100,
        paddingBottom: '5%',
      }}
    >
      <Helmet>
        <title>Mirror</title>
        <meta
          name="description"
          content="Check yourself before you wreck yourself"
        />
      </Helmet>

      <BackLink absoluteDesktop={true} />
      <h1 className="dsktp mktg termina uppercase" style={{ marginBottom: 12 }}>
        Mirror check
      </h1>
      <h4
        className="lavender-gray"
        style={{ fontWeight: 500, marginBottom: 24 }}
      >
        Prepare your camera and microphone
      </h4>
      <VideoContainer>
        <LocalVideoPreview />
      </VideoContainer>
      <form
        className="onboarding__form"
        onSubmit={handleSubmit}
        style={{ marginTop: '16px' }}
      >
        <Button
          lg
          size="height-40"
          type="submit"
          color="primary"
          style={{ width: '100%' }}
        >
          Let's go
        </Button>
        <p
          className="subtitle"
          style={{ margin: '32px 0 0', textAlign: 'center' }}
        >
          We'll bring you to the Metanet
        </p>
      </form>
    </div>
  );
}
