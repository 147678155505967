import { io } from 'socket.io-client';

const socket = io(
  process.env.NODE_ENV === 'production'
    ? `wss://api.happyhour.cam`
    : `ws://localhost:8080`
);

socket.on('connect', () => {
  console.log(socket.id);
});

export default socket;
