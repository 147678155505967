////////////////////////////////
////   Coordinate System    ////
////////////////////////////////

export enum CARTESIAN_QUADRANT {
  I = 1,
  II,
  III,
  IV,
}
