import gql from 'graphql-tag';

export const GET_USER = gql`
  query User($id: String) {
    user(id: $id) {
      id
      username
      displayName
      age
      email
      isAdmin
      gender {
        id
        value
      }
      roomInMetaspaceX
      roomInMetaspaceY
      roomInMetaspaceZ
      avatarInRoomX
      avatarInRoomY
      sexualOrientation {
        id
        value
      }
      showMe
      showMeIn
      professionPreference {
        id
        value
      }
      events {
        name
        region
        eventCode
      }
      createdAt
      updatedAt
      lastLoginAt
    }
  }
`;
