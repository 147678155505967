import gql from 'graphql-tag';

export const GET_SEXUAL_ORIENTATIONS = gql`
  query SexualOrientations {
    sexualOrientations {
      edges {
        node {
          id
          value
        }
      }
    }
  }
`;
