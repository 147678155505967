import gql from 'graphql-tag';

export const GET_MESSAGES = gql`
  query Messages(
    $roomId: String
    $senderId: String
    $receiverId: String
    $cursor: String
  ) {
    messages(
      roomId: $roomId
      senderId: $senderId
      receiverId: $receiverId
      first: 50
      after: $cursor
    ) {
      edges {
        node {
          id
          roomId
          sender {
            id
            displayName
            username
          }
          receiver {
            id
            displayName
            username
          }
          text
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
