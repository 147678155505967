import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { TwilioError } from 'twilio-video';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<string>;
  user?:
    | User
    | null
    | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  isDark: boolean;
  toggleDarkMode(): void;
  isVideoAllowed: boolean;
  toggleVideoAllowed(): void;
  timeLeft: number;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');

  const storedIsDark = localStorage.getItem('isDark');
  let prefersDark;
  if (storedIsDark === null) {
    prefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
  } else {
    prefersDark = JSON.parse(storedIsDark);
  }

  const [isDark, setIsDark] = useState<boolean>(prefersDark ? true : false);

  const toggleDarkMode = useCallback(() => {
    localStorage.setItem('isDark', JSON.stringify(!isDark));
    setIsDark(!isDark);
  }, [isDark, setIsDark]);

  const [isVideoAllowed, setIsVideoAllowed] = useState<boolean>(true);

  const toggleVideoAllowed = useCallback(() => {
    setIsVideoAllowed(!isVideoAllowed);
  }, [isVideoAllowed, setIsVideoAllowed]);

  /*
   * Time left until video is allowed
   */

  const [timeLeft, setTimeLeft] = useState<number>(
    process.env.NODE_ENV === 'development' ? 3 : 59
  );

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    isDark,
    toggleDarkMode,
    isVideoAllowed,
    toggleVideoAllowed,
    timeLeft,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (identity, roomName) => {
        const headers = new window.Headers();
        // const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
        const endpoint = `${
          process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_DEVELOPMENT_API_URL
        }/token`;
        const params = new window.URLSearchParams({ identity, roomName });

        return fetch(`${endpoint}?${params}`, { headers }).then(res =>
          res.text()
        );
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

// const calculateTimeLeft = () => {
//   const difference = +new Date('2020-04-12') - +new Date();
//   let timeLeft = {
//     days: 0,
//     hours: 0,
//     minutes: 0,
//     seconds: 0,
//   };

//   if (difference > 0) {
//     timeLeft = {
//       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//       minutes: Math.floor((difference / 1000 / 60) % 60),
//       seconds: Math.floor((difference / 1000) % 60),
//     };
//   }

//   return timeLeft;
// };

// const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

// useEffect(() => {
//   setTimeout(() => {
//     setTimeLeft(calculateTimeLeft());
//   }, 1000);
// });

// var formattedTimeLeft =
//   timeLeft?.minutes?.toString().padStart(2, '0') + ':' + timeLeft?.seconds?.toString().padStart(2, '0');

// const [resetNextSkipAt] = useMutation(RESET_NEXT_SKIP_AT, {
//   variables: { id: getMeData?.me?.id },
//   onCompleted: data => {},
// });
// resetNextSkipAt();
