import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_ROOM_AT_COORDS } from '../../graphql/queries/Room';
import { ROOMS_SUBSCRIPTION } from '../../graphql/subscriptions/Room';
import { Coordinates } from '../../models/Entities';

type MinimapType = {
  myCoords: Coordinates;
  setCoords: any;
};

export default function Minimap({ myCoords, setCoords }: MinimapType) {
  const { x: myX, y: myY, z: myZ } = myCoords;

  return (
    <div className="Minimap">
      {[myX - 2, myX - 1, myX, myX + 1, myX + 2].map(roomX => (
        <div className="Minimap__col" key={`minimap-x-col-${roomX}`}>
          {[myY + 2, myY + 1, myY, myY - 1, myY - 2].map(roomY => (
            <MinimapRoom
              key={`minimap-room-${roomX}-${roomY}`}
              roomX={roomX}
              roomY={roomY}
              myX={myX}
              myY={myY}
              myZ={myZ}
              setCoords={setCoords}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

type MinimapRoomType = {
  roomX: number;
  roomY: number;
  myX: number;
  myY: number;
  myZ: number | undefined;
  setCoords: any;
};

const MinimapRoom = ({ roomX, roomY, myX, myY, myZ }: MinimapRoomType) => {
  const isMyCurrentLocation = myX === roomX && myY === roomY;

  const { loading, data: getRoomAtCoordsData, subscribeToMore } = useQuery(
    GET_ROOM_AT_COORDS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        xCoord: roomX,
        yCoord: roomY,
        zCoord: myZ,
      },
    }
  );

  const currentParticipants =
    getRoomAtCoordsData?.roomAtCoords?.currentParticipants || [];

  useEffect(
    () =>
      subscribeToMore({
        document: ROOMS_SUBSCRIPTION,
        variables: {
          xCoord: roomX,
          yCoord: roomY,
          zCoord: myZ,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newCurrentParticipants =
            subscriptionData?.data?.roomParticipantsChanged
              ?.currentParticipants;
          return Object.assign({}, prev, {
            currentParticipants: newCurrentParticipants,
          });
        },
      }),
    [subscribeToMore, roomX, roomY, myZ]
  );

  const participantDots = () => {
    switch (currentParticipants.length) {
      case 1:
        if (isMyCurrentLocation) {
          return <circle cx="18" cy="12" r="2" fill="#2EEFA9" />;
        } else {
          return <circle cx="18" cy="12" r="1.5" fill="#FFFFFF" />;
        }
      case 2:
        if (isMyCurrentLocation) {
          return (
            <>
              <circle cx="15.75" cy="12" r="2" fill="#2EEFA9" />
              <circle cx="20.25" cy="12" r="1.5" fill="#FFFFFF" />
            </>
          );
        } else {
          return (
            <>
              <circle cx="16" cy="12" r="1.5" fill="#FFFFFF" />
              <circle cx="20" cy="12" r="1.5" fill="#FFFFFF" />
            </>
          );
        }
      case 3:
        if (isMyCurrentLocation) {
          return (
            <>
              <circle cx="13.75" cy="12" r="2" fill="#2EEFA9" />
              <circle cx="18.25" cy="12" r="1.5" fill="#FFFFFF" />
              <circle cx="22.25" cy="12" r="1.5" fill="#FFFFFF" />
            </>
          );
        } else {
          return (
            <>
              <circle cx="14" cy="12" r="1.5" fill="#FFFFFF" />
              <circle cx="18" cy="12" r="1.5" fill="#FFFFFF" />
              <circle cx="22" cy="12" r="1.5" fill="#FFFFFF" />
            </>
          );
        }
      case 4:
        if (isMyCurrentLocation) {
          return (
            <>
              <circle cx="15.75" cy="9.75" r="2" fill="#2EEFA9" />
              <circle cx="20.25" cy="9.75" r="1.5" fill="#FFFFFF" />
              <circle cx="15.75" cy="14.25" r="1.5" fill="#FFFFFF" />
              <circle cx="20.25" cy="14.25" r="1.5" fill="#FFFFFF" />
            </>
          );
        } else {
          return (
            <>
              <circle cx="16" cy="10" r="1.5" fill="#FFFFFF" />
              <circle cx="20" cy="10" r="1.5" fill="#FFFFFF" />
              <circle cx="16" cy="14" r="1.5" fill="#FFFFFF" />
              <circle cx="20" cy="14" r="1.5" fill="#FFFFFF" />
            </>
          );
        }
      default:
        return null;
    }
  };

  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      className="Minimap__room"
      key={`minimap-room-${roomX}-${roomY}-svg`}
      // onClick={() => setCoords(`${x},${y},${zCoord}`)}
    >
      <path d="M18 2L2 12L18 22L34 12L18 2Z" stroke="#4b4c4c" strokeWidth="2" />
      {loading
        ? isMyCurrentLocation && <circle cx="18" cy="12" r="2" fill="#2EEFA9" />
        : participantDots()}
    </svg>
  );
};
