import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useQuery, useMutation } from '@apollo/react-hooks';

import socket from '../../lib/socketClient';

import Chat from '../Chat/Chat';
import MetaspaceControls from '../MetaspaceControls/MetaspaceControls';
import MetaspaceHeader from '../MetaspaceHeader/MetaspaceHeader';
import LocalParticipant from '../LocalParticipant/LocalParticipant';
import RemoteParticipant from '../RemoteParticipant/RemoteParticipant';
import Elevator from '../Elevator/Elevator';

import { GET_ME } from '../../graphql/queries/Me';
import {
  JOIN_ROOM,
  LEAVE_ROOM,
  JOIN_ROOM_CONNECTED_PARTICIPANTS_LIST,
} from '../../graphql/mutations/Room';

import useDynamicRefs from '../../hooks/useDynamicRefs/useDynamicRefs';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useWindowDimensions from '../../hooks/useWindowDimensions/useWindowDimensions';
import useCoords from '../../hooks/useCoords/useCoords';
import useParticipants from '../../hooks/useParticipants/useParticipants';

import { displayNotificationToast, displayErrorToast } from '../../utils/toast';
import {
  determineCartesianQuadrant,
  isCoordinatesWithinQuadrantTriangle,
} from '../../utils/navigation';
import { getRandomInt } from '../../utils/random';
// import { isMobile } from '../../utils';

import { CARTESIAN_QUADRANT } from '../../constants';

const PageContainer = styled.div`
  background: #000000;
  position: relative;
`;

interface MetaspaceContainerInterface {
  shrunk: boolean;
}

const MetaspaceContainer = styled.div<MetaspaceContainerInterface>`
  background: #000000;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${({ shrunk }) => (shrunk ? 300 : 0)}px;
  transition: margin-right 0.15s ease-out;
`;

const RoomBorderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`;

const RemoteParticipantsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`;

interface RemoteParticipantContainerInterface {
  left: number;
  bottom: number;
}

const RemoteParticipantContainer = styled.div<
  RemoteParticipantContainerInterface
>`
  position: absolute;
  left: ${({ left }) => (left ? left : 0)}px;
  bottom: ${({ bottom }) => (bottom ? bottom : 0)}px;
  z-index: 1;
`;

export default function Metaspace() {
  const [getRef, setRef] = useDynamicRefs();

  const roomState = useRoomState();
  const { connect, room } = useVideoContext();
  const { localParticipant } = room;
  const participants = useParticipants();

  const [roomId, setRoomId] = useState<string>('');

  let storedIsChatOpen = localStorage.getItem('isChatOpen');
  if (storedIsChatOpen !== null) {
    storedIsChatOpen = JSON.parse(storedIsChatOpen);
  }
  const [isChatOpen, setIsChatOpen] = useState<boolean>(
    storedIsChatOpen ? true : false
  );
  const toggleIsChatOpen = useCallback(() => {
    localStorage.setItem('isChatOpen', JSON.stringify(!isChatOpen));
    setIsChatOpen(!isChatOpen);
  }, [isChatOpen, setIsChatOpen]);

  const { myCoords, setCoords } = useCoords();
  const { x: myX, y: myY, z: myZ } = myCoords.current;

  /*
   * Queries
   * ----------------------------------------------------------------------- */

  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });

  const userId = getMeData?.me?.id;

  /*
   * Room Connection (Mutations)
   * ----------------------------------------------------------------------- */

  /*
   * Upon succesfully joining room, add user to connected participants list
   * for capacity and Minimap tracking
   */
  const [joinRoomConnectedParticipantsList] = useMutation(
    JOIN_ROOM_CONNECTED_PARTICIPANTS_LIST,
    {
      variables: {
        xCoord: myX,
        yCoord: myY,
        zCoord: myZ,
        userId,
      },
      onCompleted: ({ joinConnectedParticipantsList: mutationData }) =>
        mutationData?.status && displayNotificationToast(mutationData?.status),
    }
  );

  const [joinRoom] = useMutation(JOIN_ROOM, {
    variables: {
      xCoord: myX,
      yCoord: myY,
      zCoord: myZ,
      userId,
    },
    onCompleted: ({ joinRoom: mutationData }) => {
      setRoomId(mutationData?.roomId);
      if (mutationData?.status) {
        // if error because room is out of bounds or something...
        displayErrorToast(mutationData?.status);
      } else {
        connect(mutationData?.token)
          .then(() => joinRoomConnectedParticipantsList())
          .catch(err => console.error(err));
        // displayNotificationToast(
        //   `Welcome to (${myX}, ${myY}, ${myZ})`
        // ); // TODO: add name of room, if any
      }
    },
  });

  useEffect(() => {
    userId && joinRoom();
  }, [userId, joinRoom]); // re-render upon identity shift... TODO: fix this bug

  /*
   * Room Disconnection
   * ----------------------------------------------------------------------- */

  const disconnect = useCallback(() => {
    if (
      room?.state === 'connected' &&
      room?.localParticipant?.state === 'connected'
    ) {
      try {
        room?.disconnect?.();
      } catch (e) {
        console.error(e);
      }
    } else {
      console.log('Tried to disconnect but room was not connected.');
    }
  }, [room]);

  /**
   * When leaving room, remove user from connectedParticipants list
   * And close Twilio Room if empty
   */
  const [leaveRoom] = useMutation(LEAVE_ROOM, {
    variables: {
      userId,
      roomId: room.name,
    },
    onCompleted: ({ leaveRoom: { status } }) =>
      process.env.NODE_ENV === 'development' && console.log(status),
    onError: err =>
      process.env.NODE_ENV === 'development' && console.error(err),
  });

  /**
   * Add listeners to leave cached room / minimap upon disconnect
   */
  const disconnectHandlerRef = useRef<() => void>(() => {});
  disconnectHandlerRef.current = () => leaveRoom();

  useEffect(() => {
    room.on('disconnected', disconnectHandlerRef.current);
    return () => {
      room.off('disconnected', disconnectHandlerRef.current);
    };
  }, [room]);

  /*
   * Navigation
   * ----------------------------------------------------------------------- */

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const roomBorderSVGRef = useRef<SVGSVGElement>(null);

  const roomDimensions = {
    x: 2880,
    y: 1662.77,
  };

  const defaultLocalParticipantCoordinates = {
    x: getRandomInt(roomDimensions.x * 0.35, roomDimensions.x * 0.65),
    y: getRandomInt(roomDimensions.y * 0.35, roomDimensions.y * 0.65),
  };

  /**
   * X,Y coordinates for local participant position in room
   * Position origin of (0,0) starts in lower left of room
   */
  const localParticipantXRef: any = React.useRef(
    defaultLocalParticipantCoordinates.x
  );
  const localParticipantYRef: any = React.useRef(
    defaultLocalParticipantCoordinates.y
  );

  /**
   * Map
   * userId as key
   */
  const remoteParticipantPositionsRef: any = React.useRef(new Map());

  /**
   * SVG viewBox translation
   * Translation origin of (0,0) starts in upper left corner of SVG Grid (3 x 3 Rooms)
   */
  const defaultViewBoxTranslation = {
    x:
      defaultLocalParticipantCoordinates.x + roomDimensions.x - windowWidth / 2,
    y:
      roomDimensions.y * 2 -
      windowHeight / 2 -
      defaultLocalParticipantCoordinates.y,
  };

  const isLocalParticipantWithinRoom = useRef<boolean | undefined>(true);

  /**
   * TODO: Memoize
   */
  const checkWallTransgression = () => {
    const currentQuadrant: CARTESIAN_QUADRANT =
      determineCartesianQuadrant(
        {
          x: localParticipantXRef.current,
          y: localParticipantYRef.current,
        },
        roomDimensions
      ) ?? 1;

    isLocalParticipantWithinRoom.current = isCoordinatesWithinQuadrantTriangle(
      {
        x: localParticipantXRef.current,
        y: localParticipantYRef.current,
      },
      currentQuadrant,
      roomDimensions
    );

    if (!isLocalParticipantWithinRoom.current) {
      disconnect();

      if (currentQuadrant === 1) {
        setCoords(null, myY + 1, null);

        /**
         * Moving to quadrant 3
         * Change x,y user coordinates in room due to Room Origin change
         * x - 1440
         * y - 960
         */
        localParticipantXRef.current =
          localParticipantXRef.current - roomDimensions.x / 2;
        localParticipantYRef.current =
          localParticipantYRef.current - roomDimensions.y / 2;
      } else if (currentQuadrant === 2) {
        setCoords(myX - 1, null, null);

        /**
         * Moving to quadrant 4
         * Change x,y user coordinates in room due to Room Origin change
         * x + 1440
         * y - 960
         */
        localParticipantXRef.current =
          localParticipantXRef.current + roomDimensions.x / 2;
        localParticipantYRef.current =
          localParticipantYRef.current - roomDimensions.y / 2;
      } else if (currentQuadrant === 3) {
        setCoords(null, myY - 1, null);

        /**
         * Moving to quadrant 1
         * Change x,y user coordinates in room due to Room Origin change
         * x + 1440
         * y + 960
         */
        localParticipantXRef.current =
          localParticipantXRef.current + roomDimensions.x / 2;
        localParticipantYRef.current =
          localParticipantYRef.current + roomDimensions.y / 2;
      } else if (currentQuadrant === 4) {
        setCoords(myX + 1, null, null);

        /**
         * Moving to quadrant 2
         * Change x,y user coordinates in room due to Room Origin change
         * x - 1440
         * y + 960
         */
        localParticipantXRef.current =
          localParticipantXRef.current - roomDimensions.x / 2;
        localParticipantYRef.current =
          localParticipantYRef.current + roomDimensions.y / 2;
      }

      isLocalParticipantWithinRoom.current = true;
      joinRoom();
    }
  };

  const movementDelta = 8;

  const localParticipantController = {
    moveUp: () => {
      localParticipantYRef.current =
        localParticipantYRef.current + movementDelta;
    },
    moveDown: () => {
      localParticipantYRef.current =
        localParticipantYRef.current - movementDelta;
    },
    moveLeft: () => {
      localParticipantXRef.current =
        localParticipantXRef.current - movementDelta * 1.5;
    },
    moveRight: () => {
      localParticipantXRef.current =
        localParticipantXRef.current + movementDelta * 1.5;
    },
  };

  const controller: any = useRef({
    38: {
      pressed: false,
      func: localParticipantController.moveUp,
    }, // Up
    40: {
      pressed: false,
      func: localParticipantController.moveDown,
    }, // Down
    37: {
      pressed: false,
      func: localParticipantController.moveLeft,
    }, // Left
    39: {
      pressed: false,
      func: localParticipantController.moveRight,
    }, // Right
    87: {
      pressed: false,
      func: localParticipantController.moveUp,
    }, // W
    83: {
      pressed: false,
      func: localParticipantController.moveDown,
    }, // S
    65: {
      pressed: false,
      func: localParticipantController.moveLeft,
    }, // A
    68: {
      pressed: false,
      func: localParticipantController.moveRight,
    }, // D
  });

  const handleKeyDown = (e: any) => {
    if (controller.current[e.keyCode] && e.target.type !== 'text') {
      controller.current[e.keyCode].pressed = true;
    }
  };

  const handleKeyUp = (e: any) => {
    if (controller.current[e.keyCode] && e.target.type !== 'text') {
      controller.current[e.keyCode].pressed = false;
    }
  };

  /**
   * Handle arrow key navigation
   */
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [room]);

  /**
   * Throttle some expensive functions
   */
  const didLocalParticipantMoveRef = useRef(false);

  const lastExecutedLocalParticipantMove = useRef<number>(Date.now());
  const localParticipantMoveInterval = 100;

  const executeLocalParticipantMoves = () => {
    Object.keys(controller.current).forEach(key => {
      const { pressed, func } = controller.current[key];
      if (pressed) {
        func();
        didLocalParticipantMoveRef.current = true;
      }
    });

    if (
      room?.name &&
      (didLocalParticipantMoveRef.current ||
        Date.now() >=
          lastExecutedLocalParticipantMove.current +
            localParticipantMoveInterval)
    ) {
      lastExecutedLocalParticipantMove.current = Date.now();
      socket.emit(`local-participant-move`, {
        roomId: room?.name,
        userId,
        x: localParticipantXRef.current,
        y: localParticipantYRef.current,
      });
    }
    didLocalParticipantMoveRef.current = false;
  };

  const didRemoteParticipantMoveRefMap = useRef(new Map());

  useEffect(() => {
    socket.on(`${room?.name}-participant-move`, data => {
      remoteParticipantPositionsRef.current.set(data.userId, data);
      didRemoteParticipantMoveRefMap.current.set(data.userId, true);
    });
  }, [room]);

  const executeRemoteParticipantMoves = () => {
    participants.forEach(({ identity }) => {
      if (
        !didLocalParticipantMoveRef.current &&
        !didRemoteParticipantMoveRefMap.current.get(identity)
      )
        return;

      const { left, bottom } = calculateRemoteParticipantAbsolutePosition(
        identity
      );

      gsap.to(getRef(identity)?.current, {
        duration: 0,
        left,
        bottom,
        ease: 'power3.linear',
      });

      didRemoteParticipantMoveRefMap.current.set(identity, false);
    });
  };

  const calculateRemoteParticipantAbsolutePosition = (id: string) => {
    const { x = 0, y = 0 } =
      remoteParticipantPositionsRef?.current?.get(id) ?? {};

    const participantContainerRef = getRef(id)?.current;

    const clientWidth = participantContainerRef?.clientWidth ?? 0;
    const clientHeight = participantContainerRef?.clientHeight ?? 0;

    const left =
      x -
      (viewBoxTranslationRef.current.x - roomDimensions.x) -
      clientWidth / 2;
    const bottom =
      y -
      (2 * roomDimensions.y -
        (viewBoxTranslationRef.current.y + windowHeight)) -
      clientHeight / 2;

    return { left, bottom };
  };

  const translateViewBox = (x: number, y: number) => {
    gsap.to(roomBorderSVGRef.current, {
      duration: 0,
      attr: {
        viewBox: `${x} ${y} ${windowWidth} ${windowHeight}`,
      },
      ease: 'power3.linear',
    });
  };

  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef: any = useRef();

  /**
   * Throttle some expensive functions
   */
  const lastExecutedCheckWallTransgression = useRef<number>(Date.now());
  const checkWallTransgressionInterval = 1000;

  const viewBoxTranslationRef: any = useRef(defaultViewBoxTranslation);

  const animate = () => {
    executeLocalParticipantMoves();

    viewBoxTranslationRef.current = {
      x: localParticipantXRef.current + roomDimensions.x - windowWidth / 2,
      y: roomDimensions.y * 2 - windowHeight / 2 - localParticipantYRef.current,
    };

    translateViewBox(
      viewBoxTranslationRef.current.x,
      viewBoxTranslationRef.current.y
    );

    executeRemoteParticipantMoves();

    if (
      Date.now() >=
      lastExecutedCheckWallTransgression.current +
        checkWallTransgressionInterval
    ) {
      lastExecutedCheckWallTransgression.current = Date.now();
      checkWallTransgression();
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  /**
   * We put room in dependency array here so that
   * rAF reinitializesupon room change, state is
   * reset and disconnect handlers are responding
   * to the right room
   */
  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [room, participants]);

  return (
    <PageContainer style={{ height: windowHeight }}>
      <Helmet>
        <title>Metanet</title>
        <meta name="description" content="Welcome to the Metanet" />
      </Helmet>

      <MetaspaceContainer shrunk={isChatOpen} style={{ height: windowHeight }}>
        <RoomBorderContainer>
          <svg
            width="8646"
            height="4992"
            viewBox={`${defaultViewBoxTranslation.x} ${defaultViewBoxTranslation.y} ${windowWidth} ${windowHeight}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={roomBorderSVGRef}
          >
            <path
              d="M1443 1664.77L2883 833.39M1443 1664.77L3 2496.15L1443 3327.54M1443 1664.77L2883 2496.16L1443 3327.54M4323 1664.77L2883 833.38L4323 2L5763 833.39L4323 1664.77ZM4323 1664.77L2883 2496.15L4323 3327.54M4323 1664.77L5763 2496.16L4323 3327.54M7203 1664.77L5763 833.38M7203 1664.77L5763 2496.15L7203 3327.54M7203 1664.77L8643 2496.16L7203 3327.54M1443 3327.54L2883 4158.93M4323 3327.54L2883 4158.92L4323 4990.31L5763 4158.93L4323 3327.54ZM7203 3327.54L5763 4158.92"
              stroke="#4b4c4c"
              strokeWidth="2.5"
            />
          </svg>
        </RoomBorderContainer>

        <RemoteParticipantsContainer>
          {participants[0] &&
            roomState === 'connected' &&
            participants.map(participant => (
              <RemoteParticipantContainer
                key={participant.identity}
                ref={setRef(participant.identity)}
                left={
                  calculateRemoteParticipantAbsolutePosition(
                    participant.identity
                  ).left
                }
                bottom={
                  calculateRemoteParticipantAbsolutePosition(
                    participant.identity
                  ).bottom
                }
              >
                <RemoteParticipant participant={participant} />
              </RemoteParticipantContainer>
            ))}
        </RemoteParticipantsContainer>

        {localParticipant && (
          <LocalParticipant participant={localParticipant} />
        )}

        <Elevator
          myCoords={myCoords.current}
          setCoords={setCoords}
          disconnect={disconnect}
          joinRoom={joinRoom}
        />

        <MetaspaceHeader myCoords={myCoords.current} setCoords={setCoords} />
        <MetaspaceControls
          isChatOpen={isChatOpen}
          toggleIsChatOpen={toggleIsChatOpen}
        />
      </MetaspaceContainer>
      {isChatOpen && <Chat roomId={roomId} />}
    </PageContainer>
  );
}
