import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
// import BackLink from '../BackLink/BackLink';
import { Link } from 'react-router-dom';
import { Card } from '../Card/Card';
import AccountSettings from './AccountSettings';
import PasswordSecuritySettings from './PasswordSecuritySettings';
import ProfileSettings from './ProfileSettings';
// import DatingPreferencesSettings from './DatingPreferencesSettings';
// import LinkedAccountsSettings from './LinkedAccountsSettings';
import styled from 'styled-components';

const NavHeader = styled.h6`
  font-weight: _font(weight-medium);
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 12px;
  line-height: 16px;
  margin: 0;

  [data-theme='dark'] & {
    color: rgba(255, 255, 255, 0.35);
  }
`;

export default function Settings() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();
  return (
    <div
      className="settings"
      style={{ height: isTabletOrMobile ? height : height - 100 }}
    >
      <div className="settings__main" style={{ margin: '16px auto' }}>
        <Card className="settings__left-nav">
          <div className="settings__left-nav__header">
            <NavHeader>Settings</NavHeader>
          </div>
          <Link to="/settings/account" className="black">
            Account
          </Link>
          <Link to="/settings/security" className="black">
            Password & Security
          </Link>
          <Link to="/settings/profile" className="black">
            Profile
          </Link>
          {/* <Link to="/settings/dating-prefs" className="black">
            Dating Preferences
          </Link> */}
          {/* <Link to="/settings/linked-accounts" className="black">
            Linked Accounts
          </Link> */}
        </Card>
        <div className="settings__content">
          <Switch>
            <Route path="/settings/account">
              <AccountSettings />
            </Route>
            <Route path="/settings/security">
              <PasswordSecuritySettings />
            </Route>
            <Route path="/settings/profile">
              <ProfileSettings />
            </Route>
            {/* <Route path="/settings/dating-prefs">
              <DatingPreferencesSettings />
            </Route> */}
            {/* <Route path="/settings/linked-accounts">
              <LinkedAccountsSettings />
            </Route> */}
            <Redirect to="/settings/account" />
          </Switch>
        </div>
      </div>
    </div>
  );
}
