import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_UNIVERSES } from '../../graphql/queries/Universe';
import { Helmet } from 'react-helmet';
import {
  SpaceCardSmall,
  SpaceCardMedium,
  CardList,
} from '../../components/Card/SpaceCard';
import { ReactComponent as MarkIcon } from '../../assets/brand/logo/mark.svg';
import { EmptyListPlaceholder } from '../../components/Placeholder/EmptyListPlaceholder/EmptyListPlaceholder';
import { PageWrapper, ContentWrapper } from '../../components/Wrapper/Wrapper';

export default function Explore() {
  const { data: getUniversesData } = useQuery(GET_UNIVERSES, {
    fetchPolicy: 'cache-and-network',
    variables: { isPrivate: false },
  });

  const universes = getUniversesData?.universes?.edges;
  const global = universes?.filter(
    (universe: any) => universe.node.type === 'GLOBAL'
  );
  const cities = universes?.filter(
    (universe: any) => universe.node.type === 'CITY'
  );
  const interestGroups = universes?.filter(
    (universe: any) => universe.node.type === 'INTEREST'
  );

  const globalParticipantsCount =
    global && global[0] ? global[0]?.node?.currentParticipants?.length : '0';

  const { data: getRecentUniversesData } = useQuery(GET_UNIVERSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      recent: true,
    },
  });

  const recentUniverses = getRecentUniversesData?.universes?.edges;

  return (
    <PageWrapper>
      <Helmet>
        <title>Explore</title>
        <meta name="description" content="Discover new conversations" />
      </Helmet>

      <ContentWrapper>
        <h2 className="termina uppercase mt-2 mb-6">Explore Metaspace</h2>

        <h4 className="termina uppercase mb-3">Popular</h4>

        {recentUniverses?.length ? (
          <CardList>
            {recentUniverses?.map(({ node }: any) => (
              <SpaceCardSmall
                key={node.id}
                id={node.id}
                title={node.name}
                subtitle={`${0} people`}
                description={node.description}
                query={`x=${0}&y=${0}&z=${0}`}
              />
            ))}
          </CardList>
        ) : (
          <EmptyListPlaceholder>
            <h4 className="placeholder-color">No spaces found</h4>
          </EmptyListPlaceholder>
        )}

        <h4 className="termina uppercase mb-3">Recommended For You</h4>

        {recentUniverses?.length ? (
          <CardList>
            {recentUniverses?.map(({ node }: any) => (
              <SpaceCardMedium
                key={node.id}
                id={node.id}
                title={node.name}
                subtitle={`${0} people`}
                description={node.description}
                isPrivate={node.isPrivate}
                createdBy={node.createdBy}
                query={`x=${0}&y=${0}&z=${0}`}
              />
            ))}
          </CardList>
        ) : (
          <EmptyListPlaceholder>
            <h4 className="placeholder-color">No spaces found</h4>
          </EmptyListPlaceholder>
        )}

        <h4 className="termina uppercase mb-3">Recently Visited</h4>

        {recentUniverses?.length ? (
          <CardList>
            {recentUniverses?.map(({ node }: any) => (
              <SpaceCardSmall
                key={node.id}
                id={node.id}
                title={node.name}
                subtitle={`${0} people`}
                description={node.description}
                query={`x=${0}&y=${0}&z=${0}`}
              />
            ))}
          </CardList>
        ) : (
          <EmptyListPlaceholder>
            <h4 className="placeholder-color">No spaces found</h4>
          </EmptyListPlaceholder>
        )}
      </ContentWrapper>

      <h1 className="dsktp mktg tagline termina uppercase lavender-gray">
        Explore new frontiers
      </h1>

      <div className="dsktp Footer Footer--mktg">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MarkIcon className="mark-icon" />
          <div className="Footer__text" style={{ marginLeft: 16 }}>
            © Metanet 2021
          </div>
        </div>
        <a href="https://www.twitter.com/happyhourcam" className="Footer__text">
          Twitter
        </a>
      </div>
    </PageWrapper>
  );
}
