import React, { useState, FormEvent, ChangeEvent } from 'react';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { validateEmail } from '../../utils/validation';
import { displayErrorToast } from '../../utils/toast';
import BackLink from '../BackLink/BackLink';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_DEVELOPMENT_API_URL;

export default function CreateAccount() {
  const history = useHistory();
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });

  if (getMeData?.me?.email && !getMeData?.me?.isGuest) {
    history.replace('/home');
  }

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();

  const createAccount = () => {
    if (!validateEmail(email)) {
      displayErrorToast('Not a valid email address.');
    } else {
      axios
        .post(`${API_URL}/register`, { email, password, id: getMeData?.me?.id })
        .then(res => {
          // Send email to user

          // axios
          //   .get(`${API_URL}/email/verify`, {
          //     params: {
          //       to: email,
          //     },
          //   })
          //   .then(res => {
          //     // this.resetForm();
          //     displayNotificationToast(
          //       'Welcome to Metanet! You have been sent an email to verify your account. Redirecting...'
          //     );

          setTimeout(() => {
            axios
              .post(
                `${API_URL}/login`,
                { email, password },
                { withCredentials: true }
              )
              .then(res => history.push('/whats-your-name'))
              .catch(err => history.push('/whats-your-name'));
          }, 300);
          // })
          // .catch(err => displayErrorToast('Something went wrong. Please try again later.'));
        })
        .catch(err =>
          displayErrorToast('Something went wrong. Please try again later.')
        );
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createAccount();
  };

  return (
    <div
      className="CreateAccount"
      style={{ height: isTabletOrMobile ? height : height - 100 }}
    >
      <Helmet>
        <title>Join Metanet</title>
        <meta
          name="description"
          content="Create an account to start socializing today"
        />
      </Helmet>

      <BackLink absoluteDesktop={true} />
      <h1
        className="dsktp mktg termina uppercase"
        style={{ margin: '0 0 24px' }}
      >
        Welcome to the party
      </h1>
      <div className="section-title--mktg">
        Create an account with your email and password
      </div>
      <form className="CreateAccount__form" onSubmit={handleSubmit}>
        <TextInput
          type="email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          placeholder="Email"
          ctnStyle={{ marginBottom: 16 }}
        />
        <TextInput
          type="password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          placeholder="Password"
          ctnStyle={{ marginBottom: 24 }}
        />
        <Button
          lg
          size="height-40"
          type="submit"
          color="primary"
          style={{ width: '100%' }}
          disabled={!email || !password}
        >
          Sign up
        </Button>
      </form>
    </div>
  );
}
