import React from 'react';
import {
  Participant as IParticipant,
  // LocalParticipant as ILocalParticipant,
  // RemoteParticipant as IRemoteParticipant,
} from 'twilio-video';
// import usePublications from '../../hooks/usePublications/usePublications';
// import usePublicationIsTrackEnabled from '../../hooks/usePublicationIsTrackEnabled/usePublicationIsTrackEnabled';
// import { useQuery } from '@apollo/react-hooks';
// import { GET_USER } from '../../graphql/queries/User';
import ProfilePhotoPlaceholder from '../../assets/images/user/profile-photo/placeholder/profile-photo-placeholder-80x80@2x.jpg';
// import { ReactComponent as AvatarPlaceholder } from '../../assets/images/user/avatar-placeholder.svg';
// import { ReactComponent as AvatarPlaceholderMuted } from '../../assets/images/user/avatar-placeholder-muted.svg';
// import { ReactComponent as VideoSubtract } from '../../assets/icons/video-subtract.svg';
// import { ReactComponent as VideoAdd } from '../../assets/icons/video-add.svg';
// import { Button } from '../Button/Button';
import { useMediaQuery } from 'react-responsive';
// import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import clsx from 'clsx';

interface VideoPlaceholderProps {
  participant: IParticipant;
}

export default function VideoPlaceholder({
  participant,
}: VideoPlaceholderProps) {
  // const publications = usePublications(participant);
  // const audioPublication = publications.find(p => p.kind === 'audio');
  // const isAudioEnabled = usePublicationIsTrackEnabled(audioPublication);
  // const { isVideoAllowed, toggleVideoAllowed, timeLeft } = useAppState();

  const { room } = useVideoContext();
  const isLocal = participant === room.localParticipant;

  // Eventually, get avatar from here
  // const { data: getUserData } = useQuery(GET_USER, {
  //   variables: { id: participant?.identity },
  //   fetchPolicy: 'cache-and-network',
  // });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <div
      className={clsx('VideoPlaceholder', {
        mbl: isTabletOrMobile,
        local: isLocal,
      })}
    >
      <div className="VideoPlaceholder__container">
        <div
          className={clsx('VideoPlaceholder__avatar', {
            mbl: isTabletOrMobile,
            local: isLocal,
          })}
        >
          <img src={ProfilePhotoPlaceholder} alt="" />
          {/* {isAudioEnabled ? <AvatarPlaceholder /> : <AvatarPlaceholderMuted />} */}
        </div>

        {/* !isLocal && isTabletOrMobile && (
          <Button
            type="button"
            color="hollow"
            onClick={() => toggleVideoAllowed()}
            disabled={!!timeLeft}
            style={{ marginTop: 40 }}
          >
            {!isVideoAllowed ? (
              <div style={{ display: 'flex' }}>
                <VideoAdd style={{ marginRight: 8 }} />
                Enable Video
                {!!timeLeft && ` in 0:${timeLeft.toString().length === 1 ? '0' : ''}${timeLeft}`}
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <VideoSubtract style={{ marginRight: 8 }} />
                Disable Video
              </div>
            )}
          </Button>
        ) */}
      </div>
    </div>
  );
}
