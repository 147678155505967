import axios from 'axios';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_DEVELOPMENT_API_URL;

export const signOut = (history?: any, refetch?: any) =>
  axios
    .post(`${API_URL}/logout`, {}, { withCredentials: true })
    .then(res => {
      history && history.push('/');
      refetch && refetch();
    })
    .catch(err => console.error(err));
