import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { UPDATE_USER } from '../../graphql/mutations/User';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import BackLink from '../BackLink/BackLink';

export default function WhatsYourWhereabouts() {
  const [whereabouts, setWhereabouts] = useState<string>('');
  const [whereaboutsValidationError] = useState<boolean>(false);
  const history = useHistory();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      setWhereabouts(me?.location || '');
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      id: getMeData?.me?.id,
      location: whereabouts,
    },
    onCompleted: data => {
      history.push('/whats-your-socials');
    },
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // let isError = false;

    // if (!whereabouts) {
    //   setWhereaboutsValidationError(true);
    //   isError = true;
    // } else {
    //   setWhereaboutsValidationError(false);
    // }

    // if (isError) {
    //   return;
    // }

    updateUser();
  };

  return (
    <div
      className="WhatsYourWhereabouts onboarding"
      style={{ height: isTabletOrMobile ? height : height - 100 }}
    >
      <Helmet>
        <title>Whereabouts</title>
        <meta name="description" content="What's your whereabouts?" />
      </Helmet>

      <BackLink absoluteDesktop={true} />
      <h1
        className="dsktp mktg gradient-purple-pink"
        style={{ margin: '0 0 24px' }}
      >
        Keep it local (or don't)
      </h1>
      <div className="section-title--mktg">
        One day, COVID negative tests in hand, maybe you'll hang out with your
        new friends
      </div>
      <form className="onboarding__form" onSubmit={handleSubmit}>
        <label htmlFor="display-name" className="mktg form__label">
          Whereabouts in the world are you?&nbsp;
          <span className="subtitle">(optional)</span>
        </label>
        <TextInput
          type="text"
          value={whereabouts}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setWhereabouts(e.target.value)
          }
          placeholder="My location"
          ctnStyle={{ marginBottom: 16 }}
        />

        <div
          className="subtitle"
          style={{ margin: '16px 0 64px 0', textAlign: 'center' }}
        >
          If you add your location, we'll lean towards matching you with people
          in your general vicinity.
        </div>

        {whereaboutsValidationError && (
          <div className="validation-error">Enter your whereabouts</div>
        )}

        <Button
          lg
          size="height-40"
          type="submit"
          color="gradient-purple-pink"
          style={{ width: '100%' }}
          // disabled={!whereabouts}
        >
          That's my location
        </Button>
      </form>
    </div>
  );
}
