import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*
 * Display general info in toast
 */
export function displayNotificationToast(msg: any) {
  toast(msg, {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}

/*
 * Display error message in toast
 */
export function displayErrorToast(msg: any) {
  toast.error(`❕ ${msg}`, {
    position: 'bottom-right',
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}
