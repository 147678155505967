import React from 'react';
import { ReactComponent as Mic } from '../../../assets/icons/mic.svg';
import { ReactComponent as MicMuted } from '../../../assets/icons/mic-muted.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '../../Button/Button';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  buttonColor?: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  return (
    <Tooltip
      title={isAudioEnabled ? 'Mute Audio' : 'Unmute Audio'}
      onClick={toggleAudioEnabled}
      placement="bottom"
      // PopperProps={{ disablePortal: true }}
    >
      <Button
        type="button"
        square
        circular
        color={props.buttonColor || 'hollow'}
        disabled={props.disabled}
        data-cy-audio-toggle
      >
        {isAudioEnabled ? <Mic /> : <MicMuted />}
      </Button>
    </Tooltip>
  );
}
