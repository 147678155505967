import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { UPDATE_USER } from '../../graphql/mutations/User';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import BackLink from '../BackLink/BackLink';

export default function WhatsYourNumber() {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberValidationError, setPhoneNumberValidationError] = useState<
    boolean
  >(false);
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();

  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      setPhoneNumber(me?.phoneNumber || '');
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      id: getMeData?.me?.id,
      phoneNumber,
    },
    onCompleted: data => {
      history.push('/create-account');
    },
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let isError = false;

    if (!phoneNumber) {
      setPhoneNumberValidationError(true);
      isError = true;
    } else {
      setPhoneNumberValidationError(false);
    }

    if (isError) {
      return;
    }

    updateUser();
  };

  if (getMeData?.me?.email && !getMeData?.me?.isGuest) {
    history.replace('/home');
  }

  return (
    <div
      className="WhatsYourNumber onboarding"
      style={{ height: isTabletOrMobile ? height : height - 100 }}
    >
      <Helmet>
        <title>What's your number</title>
        <meta name="description" content="Let's verify your identity" />
      </Helmet>

      <BackLink absoluteDesktop={true} />
      <h1
        className="dsktp mktg termina uppercase"
        style={{ margin: '0 0 24px' }}
      >
        What's your number
      </h1>
      <form className="onboarding__form" onSubmit={handleSubmit}>
        <label htmlFor="display-name" className="mktg form__label">
          Phone Number
        </label>
        <TextInput
          type="text"
          value={phoneNumber}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPhoneNumber(e.target.value)
          }
          placeholder="Phone Number"
          ctnStyle={{ marginBottom: 16 }}
        />

        <h6 style={{ margin: '16px 0 64px 0', textAlign: 'center' }}>
          This helps us prevent spam and provide a safe and secure environment.
        </h6>

        {phoneNumberValidationError && (
          <div className="validation-error">Enter a number</div>
        )}

        <Button
          lg
          size="height-40"
          type="submit"
          color="primary"
          style={{ width: '100%' }}
          disabled={!phoneNumber}
        >
          That's my number
        </Button>
      </form>
    </div>
  );
}
