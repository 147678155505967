import React from 'react';
import styled from 'styled-components';
import ElevatorArrowUp from '../../assets/room/elevator-arrow-up.svg';
import { RoomInMetaspaceCoordinates } from '../../models/Entities';
import { displayNotificationToast, displayErrorToast } from '../../utils/toast';

const Elevator = styled.div`
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  padding: 6px 0;
  width: 80px;
  height: fit-content;
  box-sizing: border-box;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

const Label = styled.h6`
  color: #ffffff;
  margin: 12px 0;
`;

const ArrowUp = styled.img`
  cursor: pointer;
  width: 30px;
  height: 40px;
  user-select: none;
  opacity: 0.9;
  transition: opacity 0.1s ease-out;

  &:hover {
    opacity: 1;
  }
`;

const ArrowDown = styled(ArrowUp)`
  transform: rotate(180deg);
`;

type Props = {
  myCoords: RoomInMetaspaceCoordinates;
  setCoords: any;
  disconnect: any;
  joinRoom: any;
};

export default function NavigationLayer({
  myCoords,
  setCoords,
  disconnect,
  joinRoom,
}: Props) {
  const { z: myZ } = myCoords;

  return (
    <Elevator>
      <ArrowUp
        src={ElevatorArrowUp}
        alt=""
        onClick={() => {
          disconnect();
          setCoords(null, null, myZ + 1);
          joinRoom();
          displayNotificationToast(`Ascended to floor ${myZ + 1}`);
        }}
      />
      <Label>Elevator</Label>
      <ArrowDown
        src={ElevatorArrowUp}
        alt=""
        onClick={() => {
          disconnect();
          setCoords(null, null, myZ - 1);
          joinRoom();
          displayNotificationToast(`Descended to floor ${myZ - 1}`);
        }}
      />
    </Elevator>
  );
}
