import { useRef } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';

/**
 * User's current coordinates based off query string
 */
export default function useCoords() {
  const [queryX, setQueryX] = useQueryParam('x', StringParam);
  const [queryY, setQueryY] = useQueryParam('y', StringParam);
  const [queryZ, setQueryZ] = useQueryParam('z', StringParam);

  const myCoords: any = useRef({
    x: parseInt(queryX ?? '0'),
    y: parseInt(queryY ?? '0'),
    z: parseInt(queryZ ?? '0'),
  });

  /**
   * TODO: Change this to function able to change coordinates and trigger room change
   */
  const setCoords = (
    x: number | undefined | null,
    y: number | undefined | null,
    z: number | undefined | null
  ) => {
    myCoords.current.x = x ?? myCoords.current.x;
    myCoords.current.y = y ?? myCoords.current.y;
    myCoords.current.z = z ?? myCoords.current.z;

    setQueryX(myCoords.current.x);
    setQueryY(myCoords.current.y);
    setQueryZ(myCoords.current.z);
  };

  return { myCoords, setCoords };
}
