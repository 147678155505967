import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

interface ITextInputProps {
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  ctnStyle?: any;
}

interface InputInterface {
  isActive?: boolean;
  hasValue?: boolean;
}

export const Input = styled.input<InputInterface>`
  display: flex;
  align-items: center;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 48px;
  width: 100%;
  margin: 0 -1px;
  box-sizing: border-box;
  outline: none;
  position: relative;
  transition: box-shadow 100ms linear, border-color 100ms ease-out;
  box-shadow: none;
  -webkit-appearance: none;
  caret-color: #2e36f2;
  z-index: 101;
  padding: 12px;
  ${({ hasValue }) => hasValue && `padding: 24px 12px 8px;`};
  border-radius: 8px;
  border-bottom-left-radius: ${({ isActive }) => isActive && 0};
  border-bottom-right-radius: ${({ isActive }) => isActive && 0};
  border: 1px solid #e5e7e9;
  ${({ isActive, theme }) =>
    isActive && `border: 1px solid ${theme.attention};`};
  ${({ isActive }) => isActive && `border-bottom: 0;`};

  [data-theme='dark'] & {
    border: 0 solid #555656;
    color: ${({ theme }) => theme.text};
    caret-color: #ffffff;
  }

  &::placeholder {
    color: #caccce;

    [data-theme='dark'] & {
      color: ${({ theme }) => theme.borderColor};
    }
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.attention};
    box-shadow: 0 0 0 8px ${({ theme }) => theme.attentionHighlight};
    ${({ isActive }) => isActive && `border-bottom: 0;`};
    ${({ isActive }) => isActive && `box-shadow: none`};

    [data-theme='dark'] & {
      border: ${({ theme }) => theme.border};
      box-shadow: 0 0 0 8px ${({ theme }) => theme.dark3};
      ${({ isActive }) => isActive && `box-shadow: none`};
    }
  }
`;

interface PlaceholderInterface {
  hasValue?: boolean;
}

export const Placeholder = styled.label<PlaceholderInterface>`
  position: absolute;
  top: auto;
  bottom: auto;
  left: 12px;
  z-index: 100;
  font-size: 16px;
  line-height: 24px;
  color: #caccce;
  pointer-events: none;
  transition: all 0.075s linear;
  font-weight: 500;

  [data-theme='dark'] & {
    color: ${({ theme }) => theme.borderColor};
  }

  ${({ hasValue, theme }) =>
    hasValue &&
    `
    top: 5px;
    left: 12px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;

    [data-theme='dark'] & {
      color: #A0A4A9;
    }
    
    input:focus ~ & {
      color: ${theme.attention};

      [data-theme='dark'] & {
        color: #FFFFFF;
      }
    }
  `};
`;

interface InputContainerInterface {
  isActive?: boolean;
}

export const InputContainer = styled.div<InputContainerInterface>`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  ${({ isActive }) => isActive && `border-radius: 8px 8px 0 0;`};

  [data-theme='dark'] & {
    background: ${({ theme }) => theme.dark3};
  }
`;

export default function TextInput({
  placeholder,
  value,
  onChange,
  ctnStyle,
  type = 'text',
}: ITextInputProps) {
  return (
    <InputContainer style={ctnStyle}>
      <Input type={type} value={value} onChange={onChange} hasValue={!!value} />
      <Placeholder hasValue={!!value}>{placeholder}</Placeholder>
    </InputContainer>
  );
}
