import React, { MouseEvent } from 'react';
import { ReactComponent as Logo } from '../../assets/logo/logo.svg';
import DarkModeToggle from '../Toggle/DarkModeToggle';
import AccountMenu from '../AccountMenu/AccountMenu';
import { Link, useHistory } from 'react-router-dom';
// import { useAppState } from '../../state';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import Tooltip from '@material-ui/core/Tooltip';
import { RectButton } from '../Button/RectButton';

export default function TopNav() {
  return (
    <div className="top-nav top-nav--thick">
      <Link to="/" style={{ display: 'flex' }}>
        <Logo className="logo animate fadeIn" />
      </Link>
      <div className="top-nav__right">
        <DarkModeToggle style={{ marginRight: 40 }} />
        <AuthLink />
      </div>
    </div>
  );
}

interface DisabledLinkProps {
  to?: string | undefined;
  children: React.ReactNode;
}

const DisabledLink = ({ to = '/', children }: DisabledLinkProps) => (
  <Tooltip title={'Coming soon'} onClick={() => {}} placement="bottom">
    <Link
      to={to}
      style={{ opacity: 0.35, cursor: 'not-allowed' }}
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}
    </Link>
  </Tooltip>
);

export function LandingTopNav() {
  const history = useHistory();
  const { data: getMeData, refetch } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <div className="top-nav top-nav--thin">
      <div className="top-nav__left">
        <Link to="/" style={{ display: 'flex' }}>
          <Logo className="logo animate fadeIn" />
        </Link>
      </div>

      {/* <div className="top-nav__center">
        <DisabledLink>About</DisabledLink>
        <DisabledLink>Block Explorer</DisabledLink>
        <DisabledLink>Resources</DisabledLink>
        <DisabledLink>Discord</DisabledLink>
      </div> */}

      <div className="top-nav__right">
        {getMeData?.me?.email ? (
          <div className="d-flex align-items-center">
            <span className="mr-4">
              <Link to="/home">Home</Link>
            </span>
            <AccountMenu refetch={refetch} />
          </div>
        ) : (
          <>
            <RectButton onClick={() => history.push('/sign-in')} color="hollow">
              Log in
            </RectButton>
            <RectButton
              onClick={() => history.push('/create-account')}
              style={{ marginLeft: 12 }}
            >
              Sign up
            </RectButton>
          </>
        )}
      </div>
    </div>
  );
}

export function AppTopNav() {
  return (
    <div className="top-nav top-nav--thin">
      <div className="top-nav__left">
        <Link to="/" style={{ display: 'flex' }}>
          <Logo className="logo animate fadeIn" />
        </Link>
      </div>

      <div className="top-nav__center">
        <Link to="/home">Home</Link>
        <Link to="/explore">Explore</Link>
        <Tooltip title={'Coming soon'} onClick={() => {}} placement="bottom">
          <Link
            to="/events"
            style={{ opacity: 0.35, cursor: 'not-allowed' }}
            onClick={e => e.preventDefault()}
          >
            Events
          </Link>
        </Tooltip>
        <Tooltip title={'Coming soon'} onClick={() => {}} placement="bottom">
          <Link
            to="/market"
            style={{ opacity: 0.35, cursor: 'not-allowed' }}
            onClick={e => e.preventDefault()}
          >
            Market
          </Link>
        </Tooltip>
        <Tooltip title={'Coming soon'} onClick={() => {}} placement="bottom">
          <Link
            to="/wallet"
            style={{ opacity: 0.35, cursor: 'not-allowed' }}
            onClick={e => e.preventDefault()}
          >
            Wallet
          </Link>
        </Tooltip>
      </div>

      <div className="top-nav__right">
        <DarkModeToggle style={{ marginRight: 40 }} />
        <AuthLink />
      </div>
    </div>
  );
}

export function AuthLink() {
  const history = useHistory();
  const { data: getMeData, refetch } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });
  return getMeData?.me?.email && !getMeData?.me?.isGuest ? (
    <AccountMenu refetch={refetch} />
  ) : history.location.pathname.indexOf('/sign-in') === 0 ? (
    <Link to="/create-account" className="ml-0">
      Create account
    </Link>
  ) : (
    <Link to="/sign-in" className="ml-0">
      Sign in
    </Link>
  );
}
