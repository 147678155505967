import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { UPDATE_USER } from '../../graphql/mutations/User';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import BackLink from '../BackLink/BackLink';

export default function WhatsYourAge() {
  const [age, setAge] = useState<string>('');
  const [ageValidationError, setAgeValidationError] = useState<boolean>(false);
  const history = useHistory();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      setAge(me?.age || '');
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      id: getMeData?.me?.id,
      age: parseInt(age),
    },
    onCompleted: data => {
      history.push('/home');
    },
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let isError = false;

    if (!age) {
      setAgeValidationError(true);
      isError = true;
    } else {
      setAgeValidationError(false);
    }

    if (isError) {
      return;
    }

    updateUser();
  };

  return (
    <div
      className="WhatsYourAge onboarding"
      style={{ height: isTabletOrMobile ? height : height - 100 }}
    >
      <Helmet>
        <title>Age</title>
        <meta name="description" content="What's your age?" />
      </Helmet>

      <BackLink absoluteDesktop={true} />
      <h1
        className="dsktp mktg gradient-purple-pink"
        style={{ margin: '0 0 24px' }}
      >
        How old are you?
      </h1>
      <div className="section-title--mktg">You must be 18+ to use Metanet</div>
      <form className="onboarding__form" onSubmit={handleSubmit}>
        <label htmlFor="display-name" className="mktg form__label">
          Whats your age?
        </label>
        <TextInput
          type="text"
          value={age}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAge(e.target.value)
          }
          placeholder="My age"
          ctnStyle={{ marginBottom: 16 }}
        />

        <div
          className="subtitle"
          style={{ margin: '16px 0 64px 0', textAlign: 'center' }}
        >
          {''}
        </div>

        {ageValidationError && (
          <div className="validation-error">Enter your age</div>
        )}

        <Button
          lg
          size="height-40"
          type="submit"
          color="gradient-purple-pink"
          style={{ width: '100%' }}
          disabled={!age}
        >
          That's my age
        </Button>
      </form>
    </div>
  );
}
