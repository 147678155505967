import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { VideoProvider } from './components/VideoProvider';
import MirrorCheck from './components/MirrorCheck/MirrorCheck';
import Metaspace from './components/Metaspace/Metaspace';
import { isMobile } from './utils';
import { ConnectOptions } from 'twilio-video';
import { useAppState } from './state';
import { VideoAppLayout } from './components/layouts';
// import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  // Bandwidth Profile, Dominant Speaker, and Network Quality
  // features are only available in Small Group or Group Rooms.
  // Please set "Room Type" to "Group" or "Small Group" in your
  // Twilio Console: https://www.twilio.com/console/video/configure
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'standard',
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  networkQuality: { local: 1, remote: 1 },

  // Comment this line if you are playing music.
  maxAudioBitrate: 16000,

  // VP8 simulcast enables the media server in a Small Group or Group Room
  // to adapt your encoded video quality for each RemoteParticipant based on
  // their individual bandwidth constraints. This has no effect if you are
  // using Peer-to-Peer Rooms.
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
};

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile && connectionOptions?.bandwidthProfile?.video) {
  connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
}

export default function VideoApp() {
  const { /* error, */ setError } = useAppState();
  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      {/* <ErrorDialog dismissError={() => setError(null)} error={error} /> */}
      <VideoAppLayout>
        <Switch>
          <Route path="/m/space">
            <Metaspace />
          </Route>
          <Route path="/m/mirror">
            <MirrorCheck />
          </Route>
          <Redirect to="/m/space" />
        </Switch>
        <ReconnectingNotification />
      </VideoAppLayout>
    </VideoProvider>
  );
}
