import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { GET_UNIVERSES } from '../../graphql/queries/Universe';
import { Helmet } from 'react-helmet';
import { Card } from '../../components/Card/Card';
import { MediumTile, TileGroup } from '../../components/Tile/Tile';
import DevelopersHero from '../../assets/images/events/developers.jpg';
import { ReactComponent as MarkIcon } from '../../assets/brand/logo/mark.svg';
import ProfilePhotoPlaceholder from '../../assets/images/user/profile-photo/placeholder/profile-photo-placeholder-80x80@2x.jpg';
import { useAppState } from '../../state';
import { EmptyListPlaceholder } from '../../components/Placeholder/EmptyListPlaceholder/EmptyListPlaceholder';
import { PageWrapper, ContentWrapper } from '../../components/Wrapper/Wrapper';
import {
  SpaceCardSmall,
  SpaceCardMedium,
  CardList,
} from '../../components/Card/SpaceCard';

export default function Home() {
  const { isDark } = useAppState();

  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });

  const { data: getRecentUniversesData } = useQuery(GET_UNIVERSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      recent: true,
    },
  });
  const recentUniverses = getRecentUniversesData?.universes?.edges;

  const [footerTagline, setFooterTagline] = useState<string>('');

  useEffect(() => {
    setFooterTagline(
      ['The metaverse is yours', 'Build the metaverse', 'Be the metaverse'][
        getRandomInt(1, 3) - 1
      ]
    );
  }, []);

  return (
    <PageWrapper className="Home">
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Your Metanet overview" />
      </Helmet>

      <div className="Home__main">
        <div className="Home__side-channel">
          <Card className="Home__profile">
            <div className="avatar avatar-80 gray">
              <img src={ProfilePhotoPlaceholder} alt="Avatar" />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 24,
              }}
            >
              <h5
                className={clsx({
                  'placeholder-color': !getMeData?.me?.displayName,
                })}
                style={{ textAlign: 'center' }}
              >
                {getMeData?.me?.displayName ?? 'No display name set'}
              </h5>
              <h6
                style={{ textAlign: 'center' }}
                title={getMeData?.me?.username}
              >
                {truncate(getMeData?.me?.username, 16)}
              </h6>
            </div>
            {/* <div
              className="subtitle"
              style={{ textAlign: 'center', margin: '2px 0 0' }}
              title={getMeData?.me?.location}
            >
              {truncate(getMeData?.me?.location || '', 100)}
            </div> */}
            {/*
            <div className="subtitle" style={{ textAlign: 'center', margin: '2px 0 0' }} title={getMeData?.me?.location}>
              {getMeData?.me?.createdAt}
            </div>
            */}
          </Card>
          {/*
            TODO: Put friends here
           */}
          {/*
          <Card className="Home__user-content Home__photos">
            <h5>{`${getMeData?.me?.displayName}'s Photos`}</h5>
            <div className="subtitle">0 photos</div>
            <h6
              style={{
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Coming soon.
            </h6>
          </Card>
          <Card className="Home__user-content Home__about-me">
            <h5>About Me</h5>
            <div className="subtitle">0 answered questions</div>
            <h6
              style={{
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Coming soon.
            </h6>
          </Card>
          */}
        </div>

        <div className="Home__content">
          <h2 className="termina uppercase mb-4">Home</h2>

          <h4 className="termina uppercase mb-3">Recently Visited</h4>

          {recentUniverses?.length ? (
            <CardList>
              {recentUniverses?.map(({ node }: any) => (
                <SpaceCardSmall
                  key={node.id}
                  id={node.id}
                  title={node.name}
                  subtitle={`${0} people`}
                  description={node.description}
                  query={`x=${0}&y=${0}&z=${0}`}
                />
              ))}
            </CardList>
          ) : (
            <EmptyListPlaceholder>
              <h4 className="placeholder-color">No spaces found</h4>
            </EmptyListPlaceholder>
          )}

          <h4 className="termina uppercase mb-3">My Spaces</h4>

          {recentUniverses?.length ? (
            <CardList>
              {recentUniverses?.map(({ node }: any) => (
                <SpaceCardMedium
                  key={node.id}
                  id={node.id}
                  title={node.name}
                  subtitle={`${0} people`}
                  description={node.description}
                  isPrivate={node.isPrivate}
                  createdBy={node.createdBy}
                  query={`x=${0}&y=${0}&z=${0}`}
                />
              ))}
            </CardList>
          ) : (
            <EmptyListPlaceholder>
              <h4 className="placeholder-color">No spaces found</h4>
              <h6 className="placeholder-color">You don't own any Metaspace</h6>
            </EmptyListPlaceholder>
          )}

          <h4 className="termina uppercase mb-3">My Items</h4>

          <EmptyListPlaceholder>
            <h4 className="placeholder-color">No items found</h4>
            <h6 className="placeholder-color">You haven't added any items</h6>
          </EmptyListPlaceholder>
        </div>
      </div>

      <h1 className="dsktp mktg tagline termina uppercase lavender-gray">
        Welcome home
      </h1>

      <div className="dsktp Footer Footer--mktg">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MarkIcon className="mark-icon" />
          <div className="Footer__text" style={{ marginLeft: 24 }}>
            © Metanet 2021
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="Footer__text" style={{ marginRight: 24 }}>
            {footerTagline}
          </div>
          <a
            href="https://www.twitter.com/happyhourcam"
            className="Footer__text"
          >
            Twitter
          </a>
        </div>
      </div>
    </PageWrapper>
  );
}

function truncate(str: string = '', n: number) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
}

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
