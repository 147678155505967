import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../global-styles';
import { lightTheme } from '../../styles/theme';
import { LandingTopNav } from '../TopNav/TopNav';

const Layout = styled.div.attrs({ className: 'landing' })`
  position: relative;
`;

interface Props {
  children?: JSX.Element | JSX.Element[];
}

export const LandingLayout = ({ children }: Props) => (
  <ThemeProvider theme={lightTheme}>
    <Layout>
      <LandingTopNav />
      {children}
    </Layout>
    <GlobalStyle theme="light" />
  </ThemeProvider>
);
