import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { DeviceSelector } from '../MenuBar/DeviceSelector/DeviceSelector';
import ToggleAudioButton from '../Controls/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Controls/ToggleVideoButton/ToggleVideoButton';
import ToggleChatButton from '../Controls/ToggleChatButton/ToggleChatButton';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 16px;
  margin: 0 auto;
`;

const ContainerLeft = styled.div`
  flex: 1;
  display: flex;
`;

const ContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

type Props = {
  isChatOpen: boolean;
  toggleIsChatOpen: any;
};

const MetaspaceControls = ({ isChatOpen, toggleIsChatOpen }: Props) => (
  <Container>
    <ContainerLeft>
      <ToggleAudioButton buttonColor="metaspace-controls-gray" />
      <ToggleVideoButton buttonColor="metaspace-controls-gray" />
      <span style={{ marginLeft: 8 }}>
        <DeviceSelector />
      </span>
    </ContainerLeft>
    <ContainerRight>
      <ToggleChatButton
        buttonColor="attention"
        isChatOpen={isChatOpen}
        toggleIsChatOpen={toggleIsChatOpen}
      />
    </ContainerRight>
  </Container>
);

export default MetaspaceControls;
