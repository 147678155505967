import gql from 'graphql-tag';

export const MESSAGES_SUBSCRIPTION = gql`
  subscription OnMessageAdded($roomId: String) {
    messageAdded(roomId: $roomId) {
      id
      roomId
      sender {
        id
        displayName
        username
      }
      receiver {
        id
        displayName
        username
      }
      text
      createdAt
      updatedAt
    }
  }
`;
