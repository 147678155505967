import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    ${props =>
      props.theme === 'dark'
        ? `
      background: #000000;
    `
        : ``}
  }
`;

export default GlobalStyle;
