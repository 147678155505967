import React, { useState, MouseEvent } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { Card } from '../Card/Card';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as MoreOptions } from '../../assets/icons/more-options.svg';
import { ReactComponent as GridPreview } from '../../assets/grid-preview.svg';
import { ReactComponent as X } from '../../assets/icons/x.svg';
import SpaceAvatar from '../../assets/images/box/box@2x.png';
import SpaceCardHeroMedium from '../../assets/images/space/space-card-hero-md@2x.png';
import SpaceCardHeroSmall from '../../assets/images/space/space-card-hero-sm@2x.png';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Menu,
  // MenuDivider,
  MenuItem,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import Modal from 'react-modal';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    border: 'none',
  },
};

Modal.setAppElement('#root');

export const CardList = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const SpaceCard = styled(Card)`
  position: relative;
  /* transform: translateY(0); */
  /* transition: box-shadow 0.12s ease-out, transform 0.12s ease-out; */
  cursor: pointer;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 24px;

  &:hover {
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.075); */
    /* transform: translateY(-3px); */
    /* ${({ disabled }) => disabled && `transform: translateY(0);`} */

    [data-theme='dark'] & {
      box-shadow: none;
    }
  }

  [data-theme='dark'] & {
    box-shadow: none;
    border-radius: 0;
  }
`;

const SpaceCardMediumComponent = styled(SpaceCard)`
  position: relative;
  background: #000000;

  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) {
    width: 240px;
    height: 256px;

    &:not(:first-child) {
      margin-left: 24px;
    }
  }

  .tile-banner {
    width: 100%;
    background: #101010;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
  }

  .tag {
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #8941fd;
    background: #f1e4ff;
    padding: 3px 6px 0;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 99;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .space-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    position: absolute;
    top: calc(calc(100% - 64px) / 2);
    left: calc(calc(100% - 64px) / 2);
    background: #ffffff;
    border: 4px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;

    img {
      width: 40px;
      height: 40px;
    }

    [data-theme='dark'] & {
      background: ${({ theme }) => theme.dark2};
      border: 4px solid ${({ theme }) => theme.dark2};
    }
  }

  .tile-footer {
    position: relative;
    padding: 0 12px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &:after {
      content: '';
      height: 24px;
      position: absolute;
      top: -24px;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .bp3-popover-wrapper {
    position: absolute;
    top: 8px;
    right: 8px;

    .bp3-popover-target {
      width: 16px;
      height: 16px;
    }
  }

  .more-options {
    width: 16px;
    height: 16px;
    border-radius: 4px;

    path {
      fill: #c6c7cc;
    }

    &:hover {
      background: #252627;

      path {
        fill: #ffffff;
      }
    }
  }
`;

type SpaceCardMediumProps = {
  id?: string;
  heroImg?: any;
  title?: string;
  subtitle?: string;
  style?: any;
  isPrivate?: boolean;
  createdBy?: any;
  isComingSoon?: boolean;
  disabled?: boolean;
  description?: string;
  tooltipTitle?: string;
  query?: string;
};

export function SpaceCardMedium({
  // heroImg,
  id,
  title,
  subtitle,
  style,
  disabled,
  isPrivate,
  createdBy,
  // isComingSoon = false,
  // description = '',
  tooltipTitle = '',
  query = '',
}: SpaceCardMediumProps) {
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });
  const allowManage =
    createdBy?.id === getMeData?.me?.id || getMeData?.me?.isAdmin;
  const PopoverContent = (
    <Menu className="TileMenu">
      {allowManage && (
        <MenuItem
          className="TileMenu__MenuItem"
          text="Manage"
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            history.push(`/space/settings?id=${id}`);
          }}
        />
      )}
      <MenuItem
        className="TileMenu__MenuItem"
        text="Share"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          setModalIsOpen(true);
        }}
      />
    </Menu>
  );

  return (
    <>
      <Tooltip title={tooltipTitle} placement="top">
        <SpaceCardMediumComponent
          style={style}
          onClick={() =>
            !disabled && history.push(`/m/space${query ? `?${query}` : ``}`)
          }
          className={clsx('SpaceCardMedium', {
            animated: !disabled,
            fadeIn: !disabled,
          })}
          disabled={disabled}
        >
          <div className="tile-banner">
            <img src={SpaceCardHeroMedium} alt="" />
          </div>

          <div className="tile-footer">
            <h6 className="termina uppercase white">{title}</h6>
            <h6 className="gray">{subtitle}</h6>
          </div>

          <Popover
            popoverClassName="TileMenu__Popover"
            content={PopoverContent}
            hoverOpenDelay={0}
            interactionKind={PopoverInteractionKind.HOVER}
            position={Position.BOTTOM_RIGHT}
          >
            <MoreOptions className="more-options" />
          </Popover>
        </SpaceCardMediumComponent>
      </Tooltip>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={modalStyle}
        contentLabel="Share Metaspace"
      >
        <div className="modal__header">
          <h3>Invite people to your metaspace</h3>
          <button className="modal__x" onClick={() => setModalIsOpen(false)}>
            <X />
          </button>
        </div>
        <h5>Share this link:</h5>
        <div className="subtitle">{`${
          process.env.NODE_ENV === 'production'
            ? `https://happyhour.cam`
            : `http://localhost:3000`
        }/welcome${query ? `?${query}` : ``}`}</div>
      </Modal>
    </>
  );
}

const SpaceCardSmallComponent = styled(SpaceCard)`
  display: flex;
  height: 64px;
  width: 100%;
  background: #000000;

  &:not(:first-child) {
    margin-left: 24px;
  }

  @media screen and (min-width: 641px) {
    width: 304px;
  }
`;

type SpaceCardSmallProps = {
  id?: string;
  title?: string;
  subtitle?: string;
  style?: any;
  isComingSoon?: boolean;
  disabled?: boolean;
  description?: string;
  tooltipTitle?: string;
  query?: string;
};

export function SpaceCardSmall({
  id,
  title,
  subtitle,
  style,
  disabled,
  // isComingSoon = false,
  // description = '',
  tooltipTitle = '',
  query = '',
}: SpaceCardSmallProps) {
  const history = useHistory();
  return (
    <Tooltip title={tooltipTitle} placement="top">
      <SpaceCardSmallComponent
        style={style}
        onClick={() =>
          !disabled && history.push(`/m/space${query ? `?${query}` : ``}`)
        }
        className={clsx('SpaceCardSmall', {
          animated: !disabled,
          fadeIn: !disabled,
        })}
        disabled={disabled}
      >
        <img
          src={SpaceCardHeroSmall}
          alt=""
          style={{ width: 64, height: '100%' }}
        />
        <div style={{ padding: '0 16px', margin: 'auto 0' }}>
          <h6 className="termina uppercase white">{title}</h6>
          <h6 className="gray">{subtitle}</h6>
        </div>
      </SpaceCardSmallComponent>
    </Tooltip>
  );
}
