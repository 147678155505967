import styled from 'styled-components';

interface ButtonInterface {
  lg?: boolean;
  attention?: boolean;
  hollow?: boolean;
  square?: boolean;
  color?: string;
  circular?: boolean;
  iconFill?: string;
  size?: string;
}

export const Button = styled.button<ButtonInterface>`
  // font-size: ${props => (props.lg ? `18` : `16`)}px;
  // line-height: ${props => (props.lg ? `26` : `24`)}px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
  ${props => (!props.lg ? `height: 48px;` : ``)}
  ${props => (props.square ? `width: 48px;` : ``)}
  // padding: ${props => (props.hollow ? `6px 20px 5px` : `8px 20px 7px`)};
  padding: 0 20px;
  ${props => (props.circular ? `padding: 0;` : ``)}
  box-shadow: none;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.hollow ? (props.attention ? `#E41BE9` : `#202020`) : `#FFFFFF`};
  background: ${props =>
    props.hollow ? `transparent` : props.attention ? `#E41BE9` : `#202020`};
  border: 1px solid #dd3131;
  box-shadow: 0 0 0 0 #ffffff;
  transition: box-shadow 100ms linear, border-color 100ms ease-out;
  white-space: nowrap;

  [data-theme='dark'] & {
    border: 1px solid #555656;
    box-shadow: 0 0 0 0 #303030;
  }

  &:hover {
    // opacity: 0.9;
    box-shadow: 0 0 0 8px #f5f6f7;

    [data-theme='dark'] & {
      opacity: 1;
      box-shadow: 0 0 0 8px #303030;
    }
  }

  ${props =>
    props.square
      ? `
  @media screen and (max-width: 640px) {
    width: 40px;
    height: 40px;
    border: 1px solid #555656;
    box-shadow: none;

    svg path {
      fill: white;
    }
  }
  `
      : ``}

  ${props => handleSize(props)};
  ${props => handleColor(props)};

  &:disabled {
    opacity: 0.45;
    cursor: wait;

    [data-theme='dark'] & {
      opacity: 0.35;
    }

    &:hover {
      opacity: 0.45;
      box-shadow: none;

      [data-theme='dark'] & {
        opacity: 0.35;
      }
    }
  }

  svg {
    margin-bottom: 2px;

    path {
      [data-theme='dark'] & {
        fill: white;
      }
    }
  }
`;

const handleSize = ({ size }: { size?: string }) => {
  switch (size) {
    case 'height-40':
      return `
        // font-size: 14px;
        // line-height: 20px;
        // font-weight: 700;
        // letter-spacing: 0.8px;
        // letter-spacing: 0.1px;
        height: 40px;
        // padding: 4px 20px 3px;
        border-radius: 20px;
        // padding: 1px 0 0;
        // text-transform: uppercase;
      `;
    default:
      return `
      `;
  }
};

// border: ${props => (props.hollow ? `1px solid ${(props.attention ? `#E41BE9` : `#202020`)}` : `1px solid transparent`)};

const handleColor = ({
  color,
  iconFill,
  theme,
}: {
  color?: string;
  iconFill?: string;
  theme?: any;
}) => {
  switch (color) {
    case 'primary':
      return `
        color: #FFFFFF;
        background: #242427;
        border-color: #242427;

        [data-theme='dark'] & {
          color: #242427;
          background: #FFFFFF;
          border-color: #FFFFFF;
        }

        &:active {
          border: 1px solid #242427;

          [data-theme='dark'] & {
            border: 1px solid #242427;
          }
        }

        &:hover {
          box-shadow: 0 0 0 8px #EBECED;
        }
        
        path {
          // fill: #FFFFFF;

          [data-theme='dark'] & {
            // fill: #242427 !important;
          }
        }
      `;
    case 'attention':
      return `
        color: #FFFFFF;
        background: ${theme.attention};
        border-color: ${theme.attention};
        box-shadow: 0 0 0 0 ${theme.attentionHighlight};

        [data-theme='dark'] & {
          border-color: ${theme.attention};
        }
        
        &:active {
          border: 1px solid ${theme.attention};

          [data-theme='dark'] & {
            border: 1px solid ${theme.attention};
          }
        }

        &:hover {
          box-shadow: 0 0 0 8px ${theme.attentionHighlight};

          [data-theme='dark'] & {
            box-shadow: 0 0 0 8px #303030;
          }
        }
        
        path {
          fill: #FFFFFF;
        }
      `;
    case 'metaspace-controls-gray':
      return `
        color: #FFFFFF;
        background: #46474A;
        border-color: #46474A;
        box-shadow: 0 0 0 0 #333336;

        [data-theme='dark'] & {
          color: #FFFFFF;
          background: #46474A;
          border-color: #46474A;
        }

        &:active {
          border: 1px solid #46474A;

          [data-theme='dark'] & {
            border: 1px solid #46474A;
          }
        }

        &:hover {
          box-shadow: 0 0 0 8px #333336;
        }
        
        path {
          fill: #FFFFFF;

          [data-theme='dark'] & {
            fill: #FFFFFF !important;
          }
        }
      `;
    case 'gradient-purple-pink':
      return `
        color: #FFFFFF;
        background: linear-gradient(292deg, #FF3DB3 -5.15%, #2E36F2 107%);
        border: 0;

        [data-theme='dark'] & {
          border: 0;
        }
        
        &:active {
          border: 0;

          [data-theme='dark'] & {
            border: 0;
          }
        }

        &:hover {
          box-shadow: 0 0 0 8px ${theme.attentionHighlight};

          [data-theme='dark'] & {
            box-shadow: 0 0 0 8px #303030;
          }
        }
        
        path {
          fill: #FFFFFF;
        }
      `;
    case 'danger':
      return `
        color: #FFFFFF;
        background: #DD3131;
        border-color: #DD3131;

        [data-theme='dark'] & {
          border-color: #DD3131;
        }

        &:active {
          border: 1px solid #DD3131;
          
          [data-theme='dark'] & {
            border: 1px solid #DD3131;
          }
        }
        
        path {
          fill: #FFFFFF;
        }
      `;
    case 'hollow':
      return `
        color: #202020;
        background: transparent;
        border-color: #E5E7E9;

        [data-theme='dark'] & {
          color: #FFFFFF;
          border-color: #555656;
        }

        &:active {
          border: 1px solid #E5E7E9;

          [data-theme='dark'] & {
            border: 1px solid #555656;
          }
        }
        
        @media screen and (max-width: 640px) {
          color: #FFFFFF;
          border-color: #555656;
          box-shadow: 0 0 0 0 #303030;

          &:active {
            border: 1px solid #555656;
          }

          &:hover {
            box-shadow: 0 0 0 8px #303030;
          }

          path {
            fill: #FFFFFF;
          }
        }
      `;
    case 'hollow-purple':
      return `
        color: ${theme.purple};
        background: transparent;
        border-color: ${theme.purple};

        [data-theme='dark'] & {
          color: #FFFFFF;
          border-color: #555656;
        }

        &:active {
          border: 1px solid ${theme.purple};

          [data-theme='dark'] & {
            border: 1px solid #555656;
          }
        }

        path {
          fill: ${theme.purple};
        }
        
        @media screen and (max-width: 640px) {
          color: ${theme.purple};
          border-color: #555656;
          box-shadow: 0 0 0 0 ${theme.purpleHighlight};

          &:active {
            border: 1px solid #555656;
          }

          &:hover {
            box-shadow: 0 0 0 8px ${theme.purpleHighlight};
          }

          path {
            fill: ${theme.purple};
          }
        }
      `;
    default:
      return `
        color: #FFFFFF;
        background: #202020;
        border-color: #202020;

        [data-theme='dark'] & {
          border-color: #202020;
        }

        &:active {
          border: 1px solid #202020;
          
          [data-theme='dark'] & {
            border: 1px solid #202020;
          }
        }
        
        path {
          fill: #FFFFFF;
        }
      `;
  }
};
