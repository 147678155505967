import React, { useCallback, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as Video } from '../../../assets/icons/video.svg';
import { ReactComponent as VideoMuted } from '../../../assets/icons/video-muted.svg';
import { Button } from '../../Button/Button';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  buttonColor?: string;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 200) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Tooltip
      title={isVideoEnabled ? 'Mute Video' : 'Unmute Video'}
      onClick={toggleVideo}
      placement="bottom"
    >
      <Button
        type="button"
        square
        circular
        color={props.buttonColor || 'hollow'}
        disabled={props.disabled}
        style={{ marginLeft: 12 }}
      >
        {isVideoEnabled ? <Video /> : <VideoMuted />}
      </Button>
    </Tooltip>
  );
}
