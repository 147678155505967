import React, { useState, FormEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import { Button } from '../Button/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { UPDATE_USER } from '../../graphql/mutations/User';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Dropdown from '../Dropdown/Dropdown';
import { GET_GENDERS } from '../../graphql/queries/Gender';
import { GET_SEXUAL_ORIENTATIONS } from '../../graphql/queries/SexualOrientation';
import BackLink from '../BackLink/BackLink';

type OptionType = {
  id?: any;
  value?: any;
} | null;

export default function DatingPreferences() {
  const [genderInputValue, setGenderInputValue] = useState<string>('');
  const [
    sexualOrientationInputValue,
    setSexualOrientationInputValue,
  ] = useState<string>('');
  const [showMeInputValue, setShowMeInputValue] = useState<string>('');
  const [showMeInInputValue, setShowMeInInputValue] = useState<string>('');
  const [gender, setGender] = useState<OptionType>(null);
  const [sexualOrientation, setSexualOrientation] = useState<OptionType>(null);
  const [showMeIn, setShowMeIn] = useState<string>('');
  const [showMe, setShowMe] = useState<string>('');

  const history = useHistory();

  const { data: getGendersData } = useQuery(GET_GENDERS, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: getSexualOrientationsData } = useQuery(
    GET_SEXUAL_ORIENTATIONS,
    { fetchPolicy: 'cache-and-network' }
  );

  const genderValues = getGendersData?.genders?.edges?.map(
    ({ node }: { node: any }) => node
  );
  const sexualOrientationValues = getSexualOrientationsData?.sexualOrientations?.edges?.map(
    ({ node }: { node: any }) => node
  );
  const showMeValues = [
    { value: 'Men' },
    { value: 'Women' },
    { value: 'Everyone' },
  ];
  const showMeInValues = [{ value: 'Men' }, { value: 'Women' }];
  // const eventValues = [{ value: 'EVTCYJ', subvalue: 'NYC Virtual Happy Hour' }];

  // const location = useLocation<{ from: Location }>();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      setGender(me?.gender || null);
      setSexualOrientation(me?.sexualOrientation || null);
      setShowMe(me?.showMe || '');
      setShowMeIn(me?.showMeIn || '');
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      id: getMeData?.me?.id,
      gender: gender?.id || '',
      sexualOrientation: sexualOrientation?.id || '',
      showMe: showMe || '',
      showMeIn: showMeIn || '',
    },
    onCompleted: data => {
      history.push('/whats-your-whereabouts');
    },
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let isError = false;

    // if (!displayName) {
    //   setDisplayNameValidationError(true);
    //   isError = true;
    // } else {
    //   setDisplayNameValidationError(false);
    // }

    if (isError) {
      return;
    }

    updateUser();
  };

  const buttonIsDisabled =
    !gender?.value ||
    (!(
      gender?.value === 'Male' ||
      gender?.value === 'Female' ||
      gender?.value === undefined ||
      gender === null
    ) &&
      !showMeIn) ||
    !sexualOrientation?.value ||
    !showMe;

  return (
    <div
      className="DatingPreferences onboarding"
      style={{ height: isTabletOrMobile ? height : height - 100 }}
    >
      <Helmet>
        <title>Dating preferences</title>
        <meta name="description" content="What are you interested in?" />
      </Helmet>

      <BackLink absoluteDesktop={true} />
      <h1
        className="dsktp mktg gradient-purple-pink"
        style={{ margin: '0 0 24px' }}
      >
        You might find a date
      </h1>
      <div className="section-title--mktg">
        Metanet will try to find you someone you're interested in
      </div>
      <form className="onboarding__form" onSubmit={handleSubmit}>
        <label htmlFor="display-name" className="mktg form__label">
          Dating preferences
          {/* &nbsp;<span className="subtitle">(optional)</span> */}
        </label>
        <Dropdown
          options={genderValues}
          placeholder="My gender"
          selectedItem={gender}
          inputValue={genderInputValue}
          onInputValueChange={(inputValue: string) =>
            setGenderInputValue(inputValue)
          }
          onChange={setGender}
          onRemoveItem={() => setGender(null)}
        />
        {!(
          (!!gender &&
            (gender.value === 'Male' ||
              gender.value === 'Female' ||
              gender.value === undefined)) ||
          gender === null
        ) && (
          <Dropdown
            options={showMeInValues}
            placeholder="Show me in searches for..."
            selectedItem={{ value: showMeIn }}
            inputValue={showMeInInputValue}
            onInputValueChange={(inputValue: string) =>
              setShowMeInInputValue(inputValue)
            }
            onChange={({ value }) => setShowMeIn(value)}
            onRemoveItem={() => setShowMeIn('')}
          />
        )}
        <Dropdown
          options={sexualOrientationValues}
          placeholder="My sexual orientation"
          selectedItem={sexualOrientation}
          inputValue={sexualOrientationInputValue}
          onInputValueChange={(inputValue: string) =>
            setSexualOrientationInputValue(inputValue)
          }
          onChange={setSexualOrientation}
          onRemoveItem={() => setSexualOrientation(null)}
        />
        <Dropdown
          options={showMeValues}
          placeholder="I'm attracted to..."
          selectedItem={{ value: showMe }}
          inputValue={showMeInputValue}
          onInputValueChange={(inputValue: string) =>
            setShowMeInputValue(inputValue)
          }
          onChange={({ value }) => setShowMe(value)}
          onRemoveItem={() => setShowMe('')}
        />

        <div
          className="subtitle"
          style={{ margin: '16px 0 64px 0', textAlign: 'center' }}
        >
          Depending on how busy the night is, Metanet will introduce you to some
          blind dates.
        </div>

        {/* {displayNameValidationError && <div className="validation-error">Enter a name</div>} */}

        <Button
          lg
          size="height-40"
          type="submit"
          color="gradient-purple-pink"
          style={{ width: '100%' }}
          disabled={buttonIsDisabled}
        >
          Save dating preferences
        </Button>
      </form>
    </div>
  );
}
