import gql from 'graphql-tag';

export const CREATE_MESSAGE = gql`
  mutation CreateMessage(
    $roomId: String
    $senderId: String
    $receiverId: String
    $text: String
  ) {
    createMessage(
      input: {
        roomId: $roomId
        senderId: $senderId
        receiverId: $receiverId
        text: $text
      }
    ) {
      message {
        id
        roomId
        sender {
          id
          displayName
          username
        }
        receiver {
          id
          displayName
          username
        }
        text
        createdAt
        updatedAt
      }
    }
  }
`;
