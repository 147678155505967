import React, { useState, FormEvent, ChangeEvent } from 'react';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import { displayErrorToast } from '../../utils/toast';
import BackLink from '../BackLink/BackLink';

export default function SignIn() {
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });

  if (getMeData?.me?.email && !getMeData?.me?.isGuest) {
    history.replace('/home');
  }

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();

  const signIn = () => {
    axios
      .post(
        `${
          process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_DEVELOPMENT_API_URL
        }/login`,
        { email, password },
        { withCredentials: true }
      )
      .then(res => history.push(location?.state?.from || { pathname: '/home' }))
      .catch(err =>
        displayErrorToast(
          'Something went wrong. Please check your login details and try again.'
        )
      );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signIn();
  };

  return (
    <div
      className="SignIn"
      style={{ height: isTabletOrMobile ? height : height - 100 }}
    >
      <Helmet>
        <title>Sign in</title>
        <meta
          name="description"
          content="Welcome back! Log back into your account"
        />
      </Helmet>

      <BackLink absoluteDesktop={true} />
      <h1
        className="dsktp mktg termina uppercase"
        style={{ margin: '0 0 24px' }}
      >
        Welcome back
      </h1>
      <div className="section-title--mktg lavender-gray">
        Sign in with your email and password
      </div>
      <form className="SignIn__form" onSubmit={handleSubmit}>
        <TextInput
          type="email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          placeholder="Email"
          ctnStyle={{ marginBottom: 16 }}
        />
        <TextInput
          type="password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          placeholder="Password"
          ctnStyle={{ marginBottom: 24 }}
        />
        <Button
          lg
          size="height-40"
          type="submit"
          color="primary"
          style={{ width: '100%' }}
          disabled={!email || !password}
        >
          Sign in
        </Button>
      </form>
    </div>
  );
}
