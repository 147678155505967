import gql from 'graphql-tag';

export const GET_ME = gql`
  query Me {
    me {
      id
      username
      email
      phoneNumber
      displayName
      age
      roomInMetaspaceX
      roomInMetaspaceY
      roomInMetaspaceZ
      avatarInRoomX
      avatarInRoomY
      isGuest
      isAdmin
      isCurrentlyParticipatingInEvent
      gender {
        id
        value
      }
      sexualOrientation {
        id
        value
      }
      showMe
      showMeIn
      professionPreference {
        id
        value
      }
      events {
        name
        region
        eventCode
      }
      facebookUsername
      instagramUsername
      twitterUsername
      tikTokUsername
      linkedInUsername
      createdAt
      updatedAt
      lastLoginAt
    }
  }
`;
