import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types';
import { Track } from 'twilio-video';
import VideoPlaceholder from '../VideoPlaceholder/VideoPlaceholder';
import { Participant } from 'twilio-video';
import { useAppState } from '../../state';

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  participant: Participant;
}

export default function VideoTrack({
  track,
  isLocal,
  priority,
  participant,
}: VideoTrackProps) {
  const { isVideoAllowed } = useAppState();
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;

    if (!el) return;

    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  const isFrontFacing =
    track.mediaStreamTrack.getSettings().facingMode !== 'environment';
  const style =
    isLocal && isFrontFacing ? { transform: 'rotateY(180deg)' } : {};

  return !isLocal && !isVideoAllowed ? (
    <VideoPlaceholder participant={participant} />
  ) : (
    <video ref={ref} style={style} />
  );
}
