import React from 'react';
import styled from 'styled-components';
// import { ReactComponent as Sphere } from '../../assets/images/sphere/sphere.svg';
// import MatrixPreview from '../../assets/images/marketing/interface/matrix-preview.png';
// import { ReactComponent as MarkIcon } from '../../assets/brand/logo/mark.svg';
// import { Button } from '../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { ReactComponent as ChevronRight } from '../../assets/icons/iconic/chevron-right.svg';
import SpaceOverview from './sections/SpaceOverview';
// import TextOverSphere from './sections/TextOverSphere';
// import ThreeTexts from './sections/ThreeTexts';
// import TextAndMetaspaceNavigationPreview from './sections/TextAndMetspaceNavigationPreview';
// import TextAndMetaspaceGalleryPreview from './sections/TextAndMetspaceGalleryPreview';
// import TextAndBlockGroup4096 from './sections/TextAndBlockGroup4096';
// import TextOverMatrixWindow from './sections/TextOverMatrixWindow';
// import Footer from './sections/Footer';
import { ReactComponent as Logo } from '../../assets/logo/logo.svg';

const LandingContainer = styled.div`
  position: relative;
  background: #ffffff;
`;

const StyledLogo = styled(Logo)`
  z-index: 999;
`;

export default function Landing() {
  const history = useHistory();
  return (
    <LandingContainer className="Landing">
      <Helmet>
        <title>Metanet</title>
        <meta name="description" content="Reimagine the internet" />
      </Helmet>
      <SpaceOverview />
      {/*
      <TextOverSphere />
      <ThreeTexts />
      <TextAndMetaspaceNavigationPreview />
      <TextAndBlockGroup4096 />
      <TextAndMetaspaceGalleryPreview />
      <TextOverMatrixWindow />
      <Footer />
      */}
    </LandingContainer>
  );
}
