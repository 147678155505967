import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CaretLeft } from '../../assets/icons/caret-left.svg';
import clsx from 'clsx';

type BackLinkType = {
  absoluteDesktop?: boolean;
  style?: any;
};

export default function BackLink({ absoluteDesktop, style }: BackLinkType) {
  const history = useHistory();
  return (
    <div
      className={clsx('back', 'link', { 'absolute-desktop': absoluteDesktop })}
      onClick={() => history.goBack()}
      style={style}
    >
      <CaretLeft className="link-icon" />
      Back
    </div>
  );
}
