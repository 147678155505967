import React, { useState, MouseEvent, ChangeEvent } from 'react';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { UPDATE_USER } from '../../graphql/mutations/User';
import { Helmet } from 'react-helmet';
import { Card } from '../Card/Card';
import { ReactComponent as AvatarPlaceholder } from '../../assets/images/user/avatar-placeholder.svg';
import { displayNotificationToast, displayErrorToast } from '../../utils/toast';

export default function AccountSettings() {
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [changesMade, setChangesMade] = useState<boolean>(false);

  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      setEmail(me?.email || '');
      setPhoneNumber(me?.phoneNumber || '');
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      id: getMeData?.me?.id,
      email,
      phoneNumber,
    },
    onCompleted: data =>
      displayNotificationToast('Your account was successfully updated!'),
    onError: err =>
      displayErrorToast('Something went wrong. Please try again later.'),
  });

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    updateUser();
  };

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
        <meta name="description" content="Manage your account" />
      </Helmet>

      <Card className="AccountSettings">
        <h2 style={{ marginBottom: 24 }}>My Account</h2>

        <div className="avatar-upload">
          <div className="avatar avatar-80">
            <AvatarPlaceholder />
          </div>
          <h6 style={{ marginLeft: 24 }}>Avatar upload coming soon!</h6>
        </div>

        <label htmlFor="display-name" className="mktg form__label">
          Email
        </label>
        <TextInput
          type="email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setChangesMade(e.target.value !== getMeData?.me?.email);
            setEmail(e.target.value);
          }}
          placeholder="Email"
          ctnStyle={{ marginBottom: 24 }}
        />

        <label htmlFor="display-name" className="mktg form__label">
          Phone number
        </label>
        <TextInput
          type="text"
          value={phoneNumber}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setChangesMade(e.target.value !== getMeData?.me?.phoneNumber);
            setPhoneNumber(e.target.value);
          }}
          placeholder="Phone number"
          ctnStyle={{ marginBottom: 24 }}
        />

        <Button
          lg
          size="height-40"
          type="button"
          color="primary"
          style={{ width: '100%' }}
          onClick={handleSubmit}
          disabled={!changesMade}
        >
          Update My Account
        </Button>
      </Card>
    </>
  );
}
