import gql from 'graphql-tag';

export const GET_GENDERS = gql`
  query Genders {
    genders {
      edges {
        node {
          id
          value
        }
      }
    }
  }
`;
