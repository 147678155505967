import React from 'react';
import clsx from 'clsx';
import { GET_ME } from '../../graphql/queries/Me';
import { useQuery } from '@apollo/react-hooks';
import {
  Menu,
  MenuItem,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import { useHistory } from 'react-router-dom';
import ProfilePhotoPlaceholder from '../../assets/images/user/profile-photo/placeholder/profile-photo-placeholder-24x24@2x.jpg';
import { signOut } from '../../utils/auth';
import { useAppState } from '../../state';

type AccountMenuType = {
  style?: any;
  refetch?: any;
};

export default function AccountMenu({ style, refetch }: AccountMenuType) {
  const history = useHistory();
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });
  const { isDark } = useAppState();

  const PopoverContent = (
    <Menu className="AccountMenu">
      {getMeData?.me?.email ? (
        <div style={{ width: '100%' }}>
          <MenuItem
            className="AccountMenu__MenuItem"
            text="Settings"
            onClick={() => history.push('/settings')}
          />
          <MenuItem
            className="AccountMenu__MenuItem"
            text="Sign out"
            onClick={() => signOut(history, refetch)}
          />
          {/*
              me.isAdmin ?
              <div>
                <MenuDivider />
                <div className='section-title'>
                  Admin Only
                </div>
                <div className='PopoverMenuItem'>
                  <div className='PopoverMenuItem__label'>
                    {`Mock ${this.props.isMockEnabled ? 'Enabled' : 'Disabled'}`}
                  </div>
                  <Toggle
                    enabled={true}
                    // enabled={this.props.isMockEnabled || false}
                    // onClick={() => this.props.toggleMock()}
                  />
                </div>
              </div>
              : null
            */}
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <MenuItem
            className="AccountMenu__MenuItem"
            text="Sign in"
            onClick={() => history.push('/sign-in')}
          />
          <MenuItem
            className="AccountMenu__MenuItem"
            text="Create account"
            onClick={() => history.push('/sign-up')}
          />
        </div>
      )}
    </Menu>
  );

  return (
    <Popover
      popoverClassName="AccountMenu__Popover"
      content={PopoverContent}
      hoverOpenDelay={0}
      interactionKind={PopoverInteractionKind.HOVER}
      position={Position.BOTTOM_RIGHT}
      // isOpen={true}
    >
      <div className="nav__avatar__ctn" style={style}>
        <div className="avatar avatar-24 light no-border">
          <img src={ProfilePhotoPlaceholder} alt="" />
        </div>
      </div>
    </Popover>
  );
}
