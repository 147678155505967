import React from 'react';
import { ReactComponent as Sun } from '../../assets/icons/sun.svg';
import { ReactComponent as Moon } from '../../assets/icons/moon.svg';
import { useAppState } from '../../state';

type DarkModeToggleType = {
  style?: any;
};

export default function DarkModeToggle({ style }: DarkModeToggleType) {
  const { isDark, toggleDarkMode } = useAppState();
  return (
    <label className="DarkModeToggle" style={style}>
      <input type="checkbox" defaultChecked={isDark} />
      <span className="check" onClick={toggleDarkMode}>
        <Sun className="sun" />
        <Moon className="moon" />
      </span>
    </label>
  );
}
