import * as React from 'react';

const map = new Map<string, React.RefObject<unknown>>();

function setRef(key: string): React.RefObject<HTMLDivElement> {
  // if (!key) return console.warn(`useDynamicRefs: Cannot set ref without key `);
  const ref = React.createRef<HTMLDivElement>();
  map.set(key, ref);
  return ref;
}

function getRef(key: string): React.RefObject<HTMLDivElement> {
  // if (!key) return console.warn(`useDynamicRefs: Cannot get ref without key`);
  return map.get(key) as React.RefObject<HTMLDivElement>;
}

function useDynamicRefs(): [
  (key: string) => React.RefObject<HTMLDivElement>,
  (key: string) => React.RefObject<HTMLDivElement>
] {
  return [getRef, setRef];
}

export default useDynamicRefs;
