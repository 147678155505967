import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SpaceOverview from '../../../assets/images/space/SpaceOverview.jpg';
import ArrowRight from '../../../assets/icons/arrow-right-large.png';
// import FadeIn from '../../../components/FadeIn/FadeIn';
import { Container } from 'react-bootstrap';

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  height: calc(100vh - 60px);
`;

const Background = styled.div`
  position: absolute;
  top: -60px;
  bottom: 0;
  right: 0;
  left: 0;

  background-image: url(${SpaceOverview});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: 1;
`;

const TextContainer = styled.div`
  z-index: 99;
  overflow: scroll;
  position: absolute;
  top: 0;
  right: 80px;
  bottom: 0;
  left: 80px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 1.15;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 6rem;
    white-space: nowrap;
    margin: 0 0 0 -6px;
  }
`;

const SubtitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 10vh;
`;

const Subtitle = styled.h4`
  line-height: 1.3;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 1.25rem;
    text-align: right;
    width: 200px;
  }
`;

const Pretitle = styled.h3`
  line-height: 1.15;
  font-weight: 500;
  margin: 20vh 0 0;

  @media (min-width: 768px) {
    font-size: 2rem;
    white-space: nowrap;
  }
`;

const Placeholder = styled.h2`
  line-height: 1.15;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    white-space: nowrap;
  }
`;

const SpaceLink = styled.a`
  line-height: 1.15;
  font-weight: 400;
  width: 100%;
  text-decoration: none;
  color: #000000;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    white-space: nowrap;
  }

  &:hover {
    text-decoration: none;
  }
`;

const BlueBlock = styled.div`
  background: #00e0ff;
  width: 68px;
  height: 4px;
  margin-right: 28px;
`;

const Input = styled.div.attrs({ className: 'animated fadeIn' })`
  display: flex;
  align-items: center;
  padding: 20px 0 0;
`;

export default function TextOverSphere() {
  const history = useHistory();
  return (
    <StyledContainer>
      <TextContainer>
        <Pretitle>The Internet...</Pretitle>
        <Title>Reimagined</Title>
        <SubtitleRow>
          <Subtitle>Infinite 3D video chat for every community</Subtitle>
        </SubtitleRow>
        <Input>
          <BlueBlock />
          <Placeholder style={{ color: '#B1B1B1' }}>select...</Placeholder>
        </Input>
        <div style={{ padding: '32px 0 40px' }}>
          {Array.from([
            'designer happy hour',
            // 'product manager',
            // 'product marketer',
            // 'account executive',
            // 'sales development',
            // 'software engineer',
            // 'designer',
            // 'investor',
            // 'founder',
            // 'growth marketer',
            // 'people ops',
            // 'happy hour',
            // 'digital nomad',
            // 'cybersecurity',
          ]).map(item => (
            <SpaceLink
              style={{ marginLeft: 96, marginBottom: 16 }}
              onClick={() => history.push(`/m/space`)}
            >
              {item}
              <img
                src={ArrowRight}
                width={36}
                height={28}
                style={{ marginTop: 6, marginLeft: 16 }}
              />
            </SpaceLink>
          ))}
        </div>
      </TextContainer>
      {/* <FadeIn duration={1000} delay={500}> */}
      <Background className="animated fadeIn" />
      {/* </FadeIn> */}
    </StyledContainer>
  );
}
