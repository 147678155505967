import styled from 'styled-components';
import { Card } from '../../Card/Card';

export const EmptyListPlaceholder = styled(Card)`
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  background: #f9fafb;
  box-shadow: none;
`;
