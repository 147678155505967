import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { GET_MESSAGES } from '../../graphql/queries/Message';
import { CREATE_MESSAGE } from '../../graphql/mutations/Message';
import { MESSAGES_SUBSCRIPTION } from '../../graphql/subscriptions/Message';
import { GET_ME } from '../../graphql/queries/Me';
import { useQuery, useMutation } from '@apollo/react-hooks';

type ChatType = {
  roomId?: string;
};

export default function Chat({ roomId }: ChatType) {
  const [messageInput, setMessageInput] = useState<string>('');

  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });

  const { data: getMessagesData, subscribeToMore } = useQuery(GET_MESSAGES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      roomId,
      senderId: '',
      receiverId: '',
    },
  });

  useEffect(
    () =>
      subscribeToMore({
        document: MESSAGES_SUBSCRIPTION,
        variables: {
          roomId,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newMessage = subscriptionData.data.messageAdded;
          // Avoid duplicates
          if (
            !prev?.messages?.edges.find(
              (edge: any) => edge.node.id === newMessage.id
            )
          ) {
            return Object.assign({}, prev, {
              messages: Object.assign({}, prev.messages, {
                edges: [
                  ...prev.messages.edges,
                  {
                    node: newMessage,
                    __typename: 'MessageEdge',
                  },
                ],
              }),
            });
          } else {
            return prev;
          }
        },
      }),
    [roomId, subscribeToMore]
  );

  const messages = getMessagesData?.messages?.edges?.map(
    ({ node }: { node: any }) => node
  );

  const [createMessage] = useMutation(CREATE_MESSAGE, {
    variables: {
      roomId,
      senderId: getMeData?.me?.id,
      receiverId: '',
      text: messageInput,
    },
    onCompleted: data => setMessageInput(''),
    onError: err => console.error(err),
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (roomId !== '' && messageInput !== '') {
      createMessage();
    }
  };

  return (
    <div className="Chat">
      <div className="Chat__header">Chat</div>
      <div className="Chat__body">
        {messages?.reverse().map((message: any, i: number) => (
          <div className="Chat__message" key={i}>
            <span className="Chat__message__sender">
              {message?.sender?.displayName}
            </span>
            <span className="Chat__message__text">{message?.text}</span>
          </div>
        ))}
      </div>
      <form className="Chat__footer" onSubmit={handleSubmit}>
        <div className="Chat__input__ctn">
          <input
            type="text"
            placeholder="Send a message"
            value={messageInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setMessageInput(e.target.value)
            }
            className="Chat__input"
          />
          <button type="submit" className="Chat__input__send-button">
            <SendIcon />
          </button>
        </div>
      </form>
    </div>
  );
}
