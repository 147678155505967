import React, { useContext } from 'react';
import Downshift from 'downshift';
import styled, { ThemeContext } from 'styled-components';
import { Input, Placeholder, InputContainer } from '../TextInput/TextInput';
import { ReactComponent as X } from '../../assets/icons/x.svg';
import { ReactComponent as CaretDown } from '../../assets/icons/caret-down.svg';
import { useAppState } from '../../state';

type OptionType = {
  id?: any;
  value?: any;
  subvalue?: any;
};

interface IDropdownProps {
  options: OptionType[];
  selectedItem: OptionType | string | null;
  // initialSelectedItem?: OptionType;
  onChange: (selectedItem: any) => void;
  onInputValueChange: (inputValue: string) => void;
  inputValue: string;
  onRemoveItem: () => void;
  placeholder?: string;
}

interface FormInterface {
  isActive?: boolean;
}

const Form = styled.form<FormInterface>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  position: relative;
  margin: 0 0 16px 0;
`;

interface MenuInterface {
  isActive?: boolean;
}

const Menu = styled.ul<MenuInterface>`
  width: 100%;
  border: ${({ isActive }) => (isActive ? 1 : 0)}px solid #e5e7e9;
  border-radius: 8px;
  margin: -1px -1px 0;
  border-top-left-radius: ${({ isActive }) => isActive && 0};
  border-top-right-radius: ${({ isActive }) => isActive && 0};
  position: relative;
  box-sizing: border-box;
  ${({ isActive, theme }) =>
    isActive && `border: 1px solid ${theme.attention};`};
  // border-top: 0;
  z-index: 104;
  background: #ffffff;
  position: absolute;
  top: calc(100% + 1px);
  overflow: hidden;
  max-height: 300px;
  overflow: auto;

  [data-theme='dark'] & {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.dark3};
    border: ${({ isActive, theme }) => isActive && theme.border};
    border-top: 0;
  }
`;

const Item = styled.li`
  list-style: none;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
`;

const NoResults = styled.li`
  list-style: none;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.2;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
`;

interface ButtonInterface {
  onClick?: any;
}

const Button = styled.button<ButtonInterface>`
  width: 24px;
  height: 24px;
  z-index: 102;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  &:hover {
    opacity: 0.75;
  }

  svg path {
    fill: #a0a4a9;

    [data-theme='dark'] & {
      fill: ${({ theme }) => theme.borderColor};
    }
  }

  &:focus {
    background: ${({ theme }) => theme.attentionHighlight};

    [data-theme='dark'] & {
      background: ${({ theme }) => theme.borderColor};
    }

    svg path {
      fill: ${({ theme }) => theme.attention};

      [data-theme='dark'] & {
        fill: ${({ theme }) => theme.white};
      }
    }
  }
`;

export default function StartConversationForm({
  options,
  onChange,
  onRemoveItem,
  selectedItem,
  placeholder,
  onInputValueChange,
  inputValue,
}: IDropdownProps) {
  const themeContext = useContext(ThemeContext);
  const { isDark } = useAppState();
  return (
    <Downshift
      selectedItem={selectedItem}
      onChange={onChange}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      itemToString={item => (typeof item === 'string' ? item : item?.value)}
      // initialSelectedItem={initialSelectedItem}
    >
      {({
        getInputProps,
        getMenuProps,
        getRootProps,
        getItemProps,
        getToggleButtonProps,
        isOpen,
        highlightedIndex,
        inputValue,
        // selectedItem,
        // clearSelection,
      }) => (
        <Form {...getRootProps()} isActive={isOpen}>
          <InputContainer isActive={isOpen}>
            <Input
              {...getInputProps()}
              type="text"
              isActive={isOpen}
              hasValue={!!inputValue}
            />
            <Placeholder hasValue={!!inputValue}>{placeholder}</Placeholder>
            <ButtonContainer>
              {inputValue ? (
                <Button
                  type="button"
                  onClick={onRemoveItem}
                  style={{ margin: '0 2px 0 -2px' }}
                >
                  <X />
                </Button>
              ) : null}
              <Button type="button" {...getToggleButtonProps()}>
                {isOpen ? (
                  <CaretDown style={{ transform: 'rotate(180deg)' }} />
                ) : (
                  <CaretDown />
                )}
              </Button>
            </ButtonContainer>
          </InputContainer>
          <Menu {...getMenuProps()} isActive={isOpen}>
            {isOpen &&
              (options && options.length ? (
                options
                  ?.filter(
                    item =>
                      !inputValue ||
                      item?.value
                        ?.toLowerCase()
                        .includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => (
                    <Item
                      {...getItemProps({
                        style: {
                          background:
                            index === highlightedIndex
                              ? isDark
                                ? '#474747'
                                : themeContext.attentionHighlight
                              : '',
                          color:
                            index === highlightedIndex && !isDark
                              ? themeContext.attention
                              : '',
                        },
                        key: item.value,
                        item,
                        index,
                      })}
                    >
                      {item.value}
                    </Item>
                  ))
              ) : (
                <NoResults>No results found</NoResults>
              ))}
          </Menu>
        </Form>
      )}
    </Downshift>
  );
}
