import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import useHeight from '../../hooks/useHeight/useHeight';
import TextInput from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ME } from '../../graphql/queries/Me';
import { UPDATE_USER } from '../../graphql/mutations/User';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { displayNotificationToast } from '../../utils/toast';

export default function WhatsYourName() {
  const [displayName, setDisplayName] = useState<string>('');
  const [displayNameValidationError, setDisplayNameValidationError] = useState<
    boolean
  >(false);
  const history = useHistory();
  // const location = useLocation<{ from: Location }>();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const height = useHeight();
  const { data: getMeData } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      setDisplayName(me?.displayName || '');
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      id: getMeData?.me?.id,
      displayName,
    },
    onCompleted: data => {
      history.push('/whats-your-age');
    },
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let isError = false;

    if (!displayName) {
      setDisplayNameValidationError(true);
      isError = true;
    } else {
      setDisplayNameValidationError(false);
    }

    if (isError) {
      return;
    }

    updateUser();
  };

  useEffect(() => {
    displayNotificationToast(
      "We've suggested your spirit animal. Feel free to change it!"
    );
  }, []);

  return (
    <div
      className="WhatsYourName onboarding"
      style={{ height: isTabletOrMobile ? height : height - 100 }}
    >
      <Helmet>
        <title>Name yourself</title>
        <meta name="description" content="What should we call you?" />
      </Helmet>

      <h1
        className="dsktp mktg gradient-purple-pink"
        style={{ margin: '0 0 24px' }}
      >
        Let's get some details
      </h1>
      <div className="section-title--mktg">
        We're creating your Metanet profile
      </div>
      <form className="onboarding__form" onSubmit={handleSubmit}>
        <label htmlFor="display-name" className="mktg form__label">
          What's your name?
        </label>
        <TextInput
          type="text"
          value={displayName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setDisplayName(e.target.value)
          }
          placeholder="Display name"
          ctnStyle={{ marginBottom: 16 }}
        />

        <div
          className="subtitle"
          style={{ margin: '16px 0 64px 0', textAlign: 'center' }}
        >
          Select something comfortable for internet use.
        </div>

        {displayNameValidationError && (
          <div className="validation-error">Enter a name</div>
        )}

        <Button
          lg
          size="height-40"
          type="submit"
          color="gradient-purple-pink"
          style={{ width: '100%' }}
          disabled={!displayName}
        >
          That's my name
        </Button>
      </form>
    </div>
  );
}
