export const isMobile = (() => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then(devices =>
      devices.every(device => !(device.deviceId && device.label))
    )
    .then(shouldAskForMediaPermissions => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(mediaStream =>
            mediaStream.getTracks().forEach(track => track.stop())
          );
      }
    });
}

export function displayTime(millisec: number) {
  const normalizeTime = (time: string): string =>
    time.length === 1 ? time.padStart(2, '0') : time;

  let seconds: string = (millisec / 1000).toFixed(0);
  let minutes: string = Math.floor(parseInt(seconds) / 60).toString();
  let hours: string = '';

  if (parseInt(minutes) > 59) {
    hours = normalizeTime(Math.floor(parseInt(minutes) / 60).toString());
    minutes = normalizeTime(
      (parseInt(minutes) - parseInt(hours) * 60).toString()
    );
  }
  seconds = normalizeTime(Math.floor(parseInt(seconds) % 60).toString());

  if (hours !== '') {
    return `${hours}:${minutes}:${seconds}`;
  }
  return `${minutes}:${seconds}`;
}
